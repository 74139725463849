import styled from 'styled-components'

export const Container = styled.section`
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
`

export const Main = styled.main`
  padding-top: 64px;
  height: 100vh;
`
