import { Loader } from '../Loader'
import { Container } from './styles'

interface IOwnProps {
  children: React.ReactNode
  loading?: boolean
  appearance?: 'primary' | 'secondary'
}

type IButtonProps = IOwnProps & React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button: React.FC<IButtonProps> = ({
  children,
  loading = false,
  appearance = 'primary',
  ...buttonProps
}) => {
  return (
    <Container
      className={appearance}
      type="button"
      {...buttonProps}
      disabled={loading}
    >
      {loading ? (
        <>
          Carregando... <Loader scale={0.5} />
        </>
      ) : (
        children
      )}
    </Container>
  )
}
