import { AuthProvider } from './AuthProvider'
import { CartProvider } from './CartProvider'
import { LoadingProvider } from './LoadingProvider'
import { ModalProvider } from './ModalProvider'

interface IHooksProps {
  children: React.ReactNode
}

export const Hooks: React.FC<IHooksProps> = ({ children }) => {
  return (
    <LoadingProvider>
      <ModalProvider>
        <AuthProvider>
          <CartProvider>{children}</CartProvider>
        </AuthProvider>
      </ModalProvider>
    </LoadingProvider>
  )
}
