import { useFormContext } from 'react-hook-form'

import { Container } from './styles'

interface IOwnProps {
  label: string
  name: string
  isWhiteBackground?: boolean
  flex?: number
  formatter?: (value: string) => string
}

type IInputProps = IOwnProps & React.InputHTMLAttributes<HTMLInputElement>

export const Input: React.FC<IInputProps> = ({
  label,
  name,
  isWhiteBackground,
  flex,
  formatter,
  ...inputProps
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <Container style={{ flex }} isWhiteBackground={isWhiteBackground}>
      <span>{label}</span>
      <input
        type="text"
        {...inputProps}
        {...register(name, {
          onChange: (e) => {
            if (formatter) {
              e.target.value = formatter(e.target.value)
            }
            if (inputProps.onChange) {
              inputProps.onChange(e)
            }
          },
          onBlur: inputProps.onBlur,
        })}
      />
      {errors[name]?.message && (
        <span className="error">{errors[name]?.message?.toString()}</span>
      )}
    </Container>
  )
}
