import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { type IInvite } from '../../../@Types/IInvite'
import { Button } from '../../../components/Button'
import { Input } from '../../../components/Input'
import { InputConfirmed } from '../../../components/InputConfirmed'
import { useModal } from '../../../hooks/ModalProvider'
import { api } from '../../../services/api'
import { confirmationDate } from '../../../services/utils/confirmationDate'
import { ModalConfirmation } from '../ModalConfirmation'
import { Container, Row } from './styles'

interface IFormProps {
  invite: IInvite
}

interface IFormData {
  email: string
  phone: string
  guests: Array<{
    id: string
    name: string
    confirmed?: boolean | null
  }>
}

export const Form: React.FC<IFormProps> = ({ invite }) => {
  const controls = useForm<IFormData>()
  const [loading, setLoading] = useState(false)
  const { openModal, closeModal } = useModal()

  const isOutOfDate = confirmationDate.getTime() < Date.now()

  const handleSubmit = async (data: IFormData) => {
    if (isOutOfDate) return

    setLoading(true)

    try {
      await api.patch('/guests/invites', data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
      openModal(
        <ModalConfirmation
          onClose={closeModal}
          presenceConfirmed={data.guests.some((value) => value.confirmed)}
        />,
      )
    }
  }

  useEffect(() => {
    controls.reset({
      email: invite?.email ?? '',
      phone: invite?.phone ?? '',
      guests:
        invite?.guests?.map((guest) => ({
          name: guest.name ?? '',
          confirmed: guest.confirmed,
          id: guest.id,
        })) || [],
    })
  }, [invite, controls])

  return (
    <FormProvider {...controls}>
      <Container onSubmit={controls.handleSubmit(handleSubmit)}>
        <Input
          name="email"
          label="E-mail"
          placeholder="E-mail para contato"
          disabled={isOutOfDate}
        />
        <Input
          name="phone"
          label="Telefone"
          placeholder="Telefone para contato"
          disabled={isOutOfDate}
        />

        {invite?.guests?.map((guest, index) => (
          <Row key={guest.id}>
            <Input
              name={`guests[${index}].name`}
              label={`Convidado ${index + 1}`}
              type="text"
              placeholder="Nome do convidado"
              flex={2}
              disabled={isOutOfDate}
            />
            <InputConfirmed
              name={`guests[${index}].confirmed`}
              label="Confirmado"
              disabled={isOutOfDate}
            />
          </Row>
        ))}

        <Button type="submit" loading={loading}>
          Confirmar
        </Button>
      </Container>
    </FormProvider>
  )
}
