import { createContext, useContext, useState } from 'react'

import { Modals } from './Modals'

interface IModalContextData {
  openModal: (modal: JSX.Element) => void
  closeModal: () => void
  modals: JSX.Element[]
}

const ModalContext = createContext<IModalContextData>(
  {} as unknown as IModalContextData,
)

interface IModalProviderProps {
  children: React.ReactNode
}

export const ModalProvider: React.FC<IModalProviderProps> = ({ children }) => {
  const [modals, setModals] = useState<JSX.Element[]>([])

  const openModal = (modal: JSX.Element) => {
    setModals([...modals, modal])
  }

  const closeModal = () => {
    const newModals = [...modals]
    newModals.pop()
    setModals(newModals)
  }

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal }}>
      {children}
      <Modals modals={modals} />
    </ModalContext.Provider>
  )
}

export function useModal() {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
