import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Check, SpinnerGap, X } from '@phosphor-icons/react'

import { type IGiftInCart } from '../../../@Types/IGiftInCart'
import { type IPurchase } from '../../../@Types/IPurchase'
import { Loader } from '../../../components/Loader'
import { useModal } from '../../../hooks/ModalProvider'
import { formatMoney } from '../../../services/utils/formatMoney'
import { getPaymentStatus } from '../../../services/utils/getPaymentStatus'
import { paymentMethodName } from '../../../services/utils/paymentMethodName'
import { InsertCodeModal } from '../InsertCodeModal'
import { Container, FloatingContainer } from './styles'

interface ITotalPanelProps {
  purchase: IPurchase | undefined
  products: IGiftInCart[]
  total: number
  loading?: boolean
}

export const TotalPanel: React.FC<ITotalPanelProps> = ({
  purchase,
  products,
  total,
  loading,
}) => {
  const navigate = useNavigate()
  const containerRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const { openModal, closeModal, modals } = useModal()

  const totalFormatted = formatMoney(total)
  const paymentMethod = paymentMethodName(purchase)
  const status = getPaymentStatus(
    purchase?.paymentData.status,
    purchase?.paymentData.statusDetail,
  )

  useEffect(() => {
    if (containerRef.current) {
      setHeight(containerRef.current.clientHeight)
    }
  }, [products])

  useEffect(() => {
    console.log('modals', modals.length)
    if (modals.length === 0) {
      if (!localStorage.getItem('@biafelipe:code')) {
        console.log('modals', modals.length)
        openModal(
          <InsertCodeModal
            onClose={() => {
              closeModal()
            }}
            onCancel={() => {
              navigate('/')
              closeModal()
            }}
          />,
        )
      }
    }
  }, [navigate, modals.length, openModal, closeModal])

  return (
    <Container style={{ height }}>
      {!loading && (
        <FloatingContainer ref={containerRef}>
          <div className="images">
            {products.slice(0, 3).map((item) => (
              <img
                key={item.gift.id}
                src={item.gift.imgURL ?? '/images/default.jpg'}
                alt={item.gift.name}
              />
            ))}
            {products.length - 3 > 0 && (
              <div className="amount-more">+{products.length - 3}</div>
            )}
          </div>
          <div className="product-names">
            <h3>Presentes:</h3>
            {products.map((item) => (
              <div key={item.gift.id} className="info" title={item.gift.name}>
                <p className="name">{item.gift.name}</p>
                <p>
                  {formatMoney(item.gift.price)} x{item.quantity}
                </p>
                <p>{formatMoney(item.gift.price * item.quantity)}</p>
              </div>
            ))}
          </div>
          <div className="total">
            <h3>Total:</h3>
            <p>{totalFormatted}</p>
          </div>

          {purchase && (
            <div className="status">
              <h3>Pagamento:</h3>
              <p>{paymentMethod}</p>
              <h3>Situação:</h3>
              <div className="status-text">
                <span className={`status-icon ${purchase.paymentData.status}`}>
                  {(purchase.paymentData.status === 'pending' ||
                    purchase.paymentData.status === 'in_process' ||
                    purchase.paymentData.status === 'authorized') && (
                    <SpinnerGap size={32} />
                  )}
                  {purchase.paymentData.status === 'approved' && (
                    <Check size={32} />
                  )}
                  {purchase.paymentData.status !== 'pending' &&
                    purchase.paymentData.status !== 'in_process' &&
                    purchase.paymentData.status !== 'authorized' &&
                    purchase.paymentData.status !== 'approved' && (
                      <X size={32} />
                    )}
                </span>
                <p>{status}</p>
              </div>
            </div>
          )}
        </FloatingContainer>
      )}

      {loading && (
        <FloatingContainer className="loading">
          <div>
            <p>Carregando...</p>
            <Loader color="#000" />
          </div>
        </FloatingContainer>
      )}
    </Container>
  )
}
