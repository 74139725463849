import styled, { keyframes } from 'styled-components'

const activeAnimation = keyframes`
  to {
    opacity: 1;
  }
`

const inactiveAnimation = keyframes`
  to {
    opacity: 0;
  }
`

export const LoadingPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  color: #fff;
  opacity: 0;

  &.active {
    animation: ${activeAnimation} 0.5s forwards;
  }

  &.inactive {
    opacity: 1;
    animation: ${inactiveAnimation} 0.5s forwards;
  }
`
