import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  background-color: rosybrown;

  .row1 {
    display: flex;
    gap: 24px;

    .address {
      flex: 1;
      width: 500px;
      max-width: 500px;
      height: 500px;
      background-color: #ffffff;
      padding: 0 48px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: 56px;
        font-weight: 500;
        margin-bottom: 16px;
        color: var(--red-500);
        margin-bottom: 32px;
        font-family: var(--font-cookie);
      }

      p {
        font-size: 32px;
        font-weight: 400;
        margin-bottom: 16px;
        font-family: var(--font-cookie);

        &.highlight {
          color: #ffb703;
        }
      }

      a {
        display: block;
        font-size: 32px;
        font-weight: 400;
        margin-top: 32px;
        text-decoration: underline;
        color: var(--red-500);
        font-family: var(--font-cookie);
      }
    }
    .photo1 {
      flex: 1;
      background-color: #555555;
      height: 500px;
      background-image: url('/images/address/address1.jpeg');
      background-size: cover;
      background-position: top 30% left 50%;
    }

    @media (max-width: 800px) {
      flex-direction: column;

      .address {
        min-height: 500px;
        height: fit-content;
        width: 100%;
        min-width: 100%;
        padding: 24px 24px;
      }

      .photo1 {
        min-height: 500px;
        width: 100%;
      }
    }
  }
  .row2 {
    display: flex;
    gap: 24px;

    .map {
      flex: 1;
      max-width: 600px;
      height: 500px;

      iframe {
        width: 100%;
      }
    }
    .photo2 {
      flex: 1;
      background-color: #555555;
      height: 500px;
      background-image: url('/images/address/address2.jpeg');
      background-size: cover;
      background-position: top 30% left 50%;
    }

    @media (max-width: 800px) {
      flex-direction: column;

      .map {
        min-height: 500px;
        width: 100%;
        max-width: 100%;
      }
      .photo2 {
        min-height: 500px;
        width: 100%;
      }
    }
  }
`
