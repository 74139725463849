import { Button } from '../../../components/Button'
import { Container } from './styles'

interface IModalConfirmationProps {
  onClose: () => void
  presenceConfirmed: boolean
}

export const ModalConfirmation: React.FC<IModalConfirmationProps> = ({
  presenceConfirmed,
  onClose,
}) => {
  return (
    <Container>
      {presenceConfirmed ? (
        <>
          <h2>Obrigado por confirmar sua presença!</h2>
          <p>Esperamos vê-lo em breve.</p>
        </>
      ) : (
        <>
          <h2>Que pena que você não poderá comparecer!</h2>
          <p>Esperamos vê-lo em outro momento.</p>
        </>
      )}

      <Button onClick={onClose}>Fechar</Button>
    </Container>
  )
}
