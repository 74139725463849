import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;

  max-width: 500px;

  h2 {
    text-align: center;
    margin: 0 auto 16px;
    max-width: 350px;

    span {
      color: var(--red-500);
    }
  }

  .buttons {
    display: flex;
    gap: 16px;
    justify-content: center;
    margin-top: 16px;
    white-space: nowrap;

    button {
      flex: 1;
      padding: 16px;
      border: none;
      border-radius: 8px;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      background-color: var(--red-500);
      color: var(--white);

      + button {
        background-color: var(--white);
        color: var(--red-500);
        border: 2px solid var(--red-500);
      }
    }

    @media (max-width: 530px) {
      flex-direction: column;
    }
  }
`
