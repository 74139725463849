import styled from 'styled-components'

export const Container = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 17px;
`

export const ModalContent = styled.div`
  max-height: 90vh;
  max-width: 90vw;
  overflow-y: auto;
`
