import axios from 'axios'

const token = localStorage.getItem('@biafelipe:token')
const code = localStorage.getItem('@biafelipe:code')
const baseURL = process.env.REACT_APP_API_URL ?? 'http://localhost:3333'

export const api = axios.create({
  baseURL,
  headers: {
    Authorization: token ? `Bearer ${token}` : undefined,
    code: code ?? undefined,
  },
})
