import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

export interface IFormData {
  name: string
  email: string
}

export interface IPasswordFormData {
  password: string
  newPassword: string
  passwordConfirmation: string
}

export const dataValidation = yupResolver(
  yup.object().shape({
    name: yup.string().required('Nome obrigatório'),
    email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
  }),
)

export const passwordValidation = yupResolver(
  yup.object().shape({
    password: yup
      .string()
      .required('Senha obrigatória')
      .min(6, 'No mínimo 6 caracteres'),
    newPassword: yup
      .string()
      .required('Nova Senha obrigatória')
      .min(6, 'No mínimo 6 caracteres'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('newPassword')], 'As senhas devem ser iguais')
      .required('Confirmação de senha obrigatória'),
  }),
)
