import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { PlusCircle, X } from '@phosphor-icons/react'

import { Button } from '../../../../components/Button'
import { Input } from '../../../../components/Input'
import { InputConfirmed } from '../../../../components/InputConfirmed'
import { TitleContainer } from '../../../../components/TitleContainer'
import { api } from '../../../../services/api'
import { type IFormData, validation } from './form.validation'
import { ButtonAddGuest, Container, Form, Row } from './styles'

export const AddInvite: React.FC = () => {
  const navigate = useNavigate()
  const controls = useForm<IFormData>({ resolver: validation })
  const [guests, setGuests] = useState<number[]>([])

  const handleBack = () => {
    navigate('/admin/invites')
  }

  async function handleSubmit(data: IFormData) {
    const dataToSend = {
      name: data.name,
      email: data.email ?? undefined,
      phone: data.phone ?? undefined,
      guests: data.guests?.map((guest) => {
        return {
          name: guest.name,
          table: Number(guest.table) || undefined,
          confirmed: guest.confirmed ?? undefined,
        }
      }),
    }
    await api.post('/invites', dataToSend)
    navigate('/admin/invites')
  }

  function addGuest() {
    setGuests((state) => {
      const newOne = state.length
      return [...state, newOne]
    })
  }

  function removeGuest(guest: number) {
    setGuests((state) =>
      state.filter((item) => item !== guest).map((item, index) => index),
    )

    const guestsValues = controls.getValues('guests')
    const newGuests = guestsValues?.filter((item, index) => index !== guest)

    controls.setValue('guests', newGuests)
  }

  return (
    <Container>
      <TitleContainer>
        <h1>Adicionar Convidado</h1>
        <Button type="button" style={{ opacity: 0.5 }} onClick={handleBack}>
          Voltar
        </Button>
      </TitleContainer>

      <FormProvider {...controls}>
        <Form onSubmit={controls.handleSubmit(handleSubmit)} autoComplete="off">
          <Input name="name" label="Nome" type="text" placeholder="Nome" />
          <Input name="email" label="E-mail" type="text" placeholder="E-mail" />
          <Input name="phone" label="Telefone" placeholder="Telefone" />

          <h2>Convidados ({guests.length})</h2>
          {guests.map((guest) => (
            <Row key={guest}>
              <button
                type="button"
                className="close"
                onClick={() => {
                  removeGuest(guest)
                }}
              >
                <X size={32} />
              </button>
              <Input
                name={`guests[${guest}].name`}
                label={`Convidado ${guest + 1}`}
                type="text"
                placeholder="Nome do convidado"
                flex={2}
              />
              <Input
                name={`guests[${guest}].table`}
                label="Mesa"
                type="text"
                placeholder="Nº da Mesa"
                flex={1}
              />
              <InputConfirmed
                name={`guests[${guest}].confirmed`}
                label="Confirmado"
              />
            </Row>
          ))}
          <ButtonAddGuest type="button" onClick={addGuest}>
            <PlusCircle size={32} />
            Adicionar Convidado
          </ButtonAddGuest>
          <Button type="submit">Cadastrar</Button>
        </Form>
      </FormProvider>
    </Container>
  )
}
