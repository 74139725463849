import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem;

  h1 {
    color: var(--black);
    font-size: 2.4rem;
  }

  .actions {
    display: flex;
    gap: 1.6rem;

    input {
      padding: 1.6rem;
      border-radius: 0.8rem;
      border: 0.2rem solid #ccc;
      height: 4.8rem;
    }
  }
`

export const InviteList = styled.ul`
  width: 100%;
  max-width: 80vw;
  padding: 1.6rem 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  > li.empty {
    width: 100%;
    height: 20rem;
    color: #888;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
