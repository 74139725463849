import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AdminMenu } from '../../../components/admin/AdminMenu'
import { useAuth } from '../../../hooks/AuthProvider'
import { Container, Main } from './styles'

interface IAdminLayoutProps {
  children: React.ReactNode
}
export const AdminLayout: React.FC<IAdminLayoutProps> = ({ children }) => {
  const { isLogged } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!isLogged) {
      navigate('/admin/login')
    }
  }, [isLogged, navigate])

  if (!isLogged) {
    return <h2>Redirecionando...</h2>
  }

  return (
    <Container>
      <AdminMenu />
      <Main>{children}</Main>
    </Container>
  )
}
