export const moneyInputFormatter = (value: string) => {
  const onlyNumbers = value.replace(/[^\d]/g, '')
  const formattedValue = Number(onlyNumbers) / 100
  if (isNaN(formattedValue)) return 'R$ 0,00'
  if (formattedValue === 0) return 'R$ 0,00'
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(formattedValue)
}
