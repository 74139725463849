import styled, { keyframes } from 'styled-components'

const animation = keyframes`
  to {
    transform: rotate(.5turn)
  }
`

export const Container = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 8px solid;
  border-color: ${(props) => props.color} #0000;
  animation: ${animation} 1s infinite;
`
