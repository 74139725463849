import styled from 'styled-components'

export const Container = styled.header`
  background-image: url('/images/top-flowers.png');
  background-repeat: no-repeat;
  background-size: auto 50vh;
  height: 100vh;

  @media (max-width: 1000px) {
    height: fit-content;
    padding: 200px 0 100px;
  }
`

export const Cover = styled.section`
  height: 100vh;
  position: relative;

  .and {
    position: absolute;
    top: 80%;
    left: 49%;
    transform: translate(-50%, -50%);
    font-size: 15rem;
    color: var(--red-500);
    font-family: var(--font-greatvibes);
    z-index: 1;

    @media (max-width: 1000px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media (max-width: 1000px) {
    height: fit-content;
  }
`

export const AlbumContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 10rem;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
`

export const PhotoContainer = styled.div`
  position: relative;

  .photo-decoration {
    position: absolute;
    display: flex;
    top: 100%;
    left: 0;
    transform: translate(-50%, -50%);

    @media (max-width: 1000px) {
      width: 50%;
    }
  }
`

export const Photo = styled.div`
  display: flex;
  flex-direction: column;
  width: 40rem;
  height: 50rem;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.2);
  transition-duration: 1s;

  &.photo1 {
    transform: rotateZ(5deg);
  }
  &.photo2 {
    transform: rotateZ(-5deg);
  }

  h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8rem;
    font-family: var(--font-windsong);
    color: var(--gray-800);
    text-align: center;
    padding: 0.5rem;
    background-image: var(--text-gold-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;

    &::after {
      content: attr(title);
      position: absolute;
      z-index: -1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8rem;
      font-family: var(--font-windsong);
      text-align: center;
      padding: 0.5rem;
    }

    @media (max-width: 470px) {
      font-size: 6rem;
    }
  }

  @media (max-width: 470px) {
    width: 30rem;
    height: 37.5rem;
  }
`

export const PhotoImage = styled.div`
  width: 98%;
  height: 80%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 0 0.8rem rgba(0, 0, 0, 0.2);

  img {
    display: block;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    position: relative;
    transition: 0.5s ease-in-out;
  }
`
