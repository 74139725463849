import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { type IGift } from '../../../../@Types/IPurchase'
import { Button } from '../../../../components/Button'
import { Input } from '../../../../components/Input'
import { InputImage } from '../../../../components/InputImage'
import { TitleContainer } from '../../../../components/TitleContainer'
import { api } from '../../../../services/api'
import { formatMoney } from '../../../../services/utils/formatMoney'
import { moneyInputFormatter } from '../../../../services/utils/moneyInputFormatter'
import { type IFormData, validation } from './form.validation'
import { Container, Form } from './styles'

export const EditGift: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const controls = useForm<IFormData>({ resolver: validation })
  const [file, setFile] = useState<File | null>(null)
  const [preview, setPreview] = useState<string>('/images/default.jpg')

  const handleBack = () => {
    navigate('/admin/gifts')
  }

  const getGift = useCallback(async () => {
    if (!id) return
    const response = await api.get<IGift>(`/gifts/${id}`)
    controls.reset({
      name: response.data.name,
      price: formatMoney(response.data.price),
      limit: response.data.limit,
    })
    if (response.data.imgURL) {
      setPreview(response.data.imgURL)
    }
  }, [controls, id])

  async function handleSubmit(data: IFormData) {
    if (!id) return
    const price = Number(data.price.replace(/\D/g, '')) / 100
    await api.patch<IGift>(`/gifts/${id}`, { ...data, price })
    if (file) {
      const formData = new FormData()
      formData.append('image', file)
      await api.post(`/gifts/${id}/image`, formData)
    }
    navigate('/admin/gifts')
  }

  useEffect(() => {
    getGift().catch(console.error)
  }, [getGift, id])

  return (
    <Container>
      <TitleContainer>
        <h1>Atualizar Presente</h1>
        <Button type="button" style={{ opacity: 0.5 }} onClick={handleBack}>
          Voltar
        </Button>
      </TitleContainer>

      <FormProvider {...controls}>
        <Form onSubmit={controls.handleSubmit(handleSubmit)} autoComplete="off">
          <InputImage file={file} setFile={setFile} initialPreview={preview} />
          <Input name="name" label="Nome" type="text" placeholder="Nome" />
          <Input
            name="price"
            label="Preço"
            type="text"
            placeholder="Preço"
            formatter={moneyInputFormatter}
          />
          <Input
            name="limit"
            label="Limite"
            type="number"
            placeholder="Limite"
          />

          <Button type="submit">Atualizar</Button>
        </Form>
      </FormProvider>
    </Container>
  )
}
