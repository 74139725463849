import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { type IGift } from '../../../../@Types/IPurchase'
import { Button } from '../../../../components/Button'
import { ModalYesNo } from '../../../../components/ModalYesNo'
import { TitleContainer } from '../../../../components/TitleContainer'
import { useLoading } from '../../../../hooks/LoadingProvider'
import { useModal } from '../../../../hooks/ModalProvider'
import { api } from '../../../../services/api'
import { GiftRow } from './GiftRow'
import { Container, GiftList } from './styles'

export const Gifts: React.FC = () => {
  const navigate = useNavigate()
  const [gifts, setGifts] = useState<IGift[]>([])
  const { openModal, closeModal } = useModal()
  const { loading, setLoading } = useLoading()

  const loadGifts = useCallback(async () => {
    setLoading(true)
    const response = await api.get('/gifts')
    setGifts(response.data)
    setLoading(false)
  }, [setLoading])

  async function deleteGift(gift: IGift) {
    await api.delete(`/gifts/${gift.id}`)
    loadGifts().catch(console.error)
    closeModal()
  }

  function handleDelete(gift: IGift) {
    openModal(
      <ModalYesNo
        title="Deletar Presente"
        message={`Tem certeza que quer deletar o presente ${gift.name}?`}
        onClose={closeModal}
        onNo={closeModal}
        onYes={() => {
          deleteGift(gift).catch(console.error)
        }}
      />,
    )
  }

  function handleEdit(gift: IGift) {
    navigate(`/admin/gifts/edit/${gift.id}`)
  }

  function handleAddGift() {
    navigate('/admin/gifts/add')
  }

  useEffect(() => {
    loadGifts().catch(console.error)
  }, [loadGifts])

  return (
    <Container>
      <TitleContainer>
        <h1>Presentes</h1>
        <Button type="button" onClick={handleAddGift}>
          Adicionar
        </Button>
      </TitleContainer>

      <GiftList>
        {gifts.map((gift) => (
          <GiftRow
            key={gift.id}
            gift={gift}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        ))}
        {gifts.length === 0 && !loading && (
          <li className="empty">Nenhum presente cadastrado</li>
        )}
      </GiftList>
    </Container>
  )
}
