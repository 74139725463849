import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { type IGift } from '../../../../@Types/IPurchase'
import { Button } from '../../../../components/Button'
import { Input } from '../../../../components/Input'
import { InputImage } from '../../../../components/InputImage'
import { TitleContainer } from '../../../../components/TitleContainer'
import { api } from '../../../../services/api'
import { moneyInputFormatter } from '../../../../services/utils/moneyInputFormatter'
import { type IFormData, validation } from './form.validation'
import { Container, Form } from './styles'

export const AddGift: React.FC = () => {
  const navigate = useNavigate()
  const controls = useForm<IFormData>({ resolver: validation })
  const [file, setFile] = useState<File | null>(null)

  const handleBack = () => {
    navigate('/admin/gifts')
  }

  async function handleSubmit(data: IFormData) {
    const price = Number(data.price.replace(/\D/g, '')) / 100
    const response = await api.post<IGift>('/gifts', { ...data, price })
    const { id } = response.data
    if (file) {
      const formData = new FormData()
      formData.append('image', file)
      await api.post(`/gifts/${id}/image`, formData)
    }
    navigate('/admin/gifts')
  }

  return (
    <Container>
      <TitleContainer>
        <h2>Adicionar Presente</h2>
        <Button type="button" style={{ opacity: 0.5 }} onClick={handleBack}>
          Voltar
        </Button>
      </TitleContainer>

      <FormProvider {...controls}>
        <Form onSubmit={controls.handleSubmit(handleSubmit)} autoComplete="off">
          <InputImage file={file} setFile={setFile} />
          <Input name="name" label="Nome" type="text" placeholder="Nome" />
          <Input
            name="price"
            label="Preço"
            type="text"
            placeholder="Preço"
            formatter={moneyInputFormatter}
          />
          <Input
            name="limit"
            label="Limite"
            type="number"
            placeholder="Limite"
          />

          <Button type="submit">Cadastrar</Button>
        </Form>
      </FormProvider>
    </Container>
  )
}
