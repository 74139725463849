import { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { List, ShoppingCart, X } from '@phosphor-icons/react'

import { useCart } from '../../hooks/CartProvider'
import { Cart, Container, MenuContent, MenuMobile, ToggleMenu } from './styles'

export const Menu: React.FC = () => {
  const location = useLocation()
  const isHome = location.pathname === '/'
  const { cart } = useCart()
  const cartItemsCount = cart.reduce((acc, item) => acc + item.quantity, 0)
  const [open, setOpen] = useState(false)

  const className = useMemo(() => {
    const classes = []
    if (cartItemsCount > 0) classes.push('has-cart')
    if (open) classes.push('open')
    return classes.join(' ')
  }, [cartItemsCount, open])

  return (
    <Container className={className}>
      <MenuMobile className={className}>
        {cartItemsCount > 0 && (
          <Cart className="mobile">
            <Link to="/carrinho" className="cart">
              <ShoppingCart size={32} />
              <span className="cart-items-count">{cartItemsCount}</span>
            </Link>
          </Cart>
        )}

        <h1>Casamento de Bia & Felipe</h1>

        <ToggleMenu
          onClick={() => {
            setOpen(!open)
          }}
        >
          {!open && <List size={32} />}
          {open && <X size={32} />}
        </ToggleMenu>
      </MenuMobile>
      <MenuContent open={open}>
        {isHome && (
          <>
            <a href="#home">Início</a>
            <a href="#data">Data e Hora</a>
            <a href="#endereco">Endereço</a>
            <a href="#presentes">Presentes</a>
            <a href="#presenca">Confirme sua presença</a>
          </>
        )}
        {!isHome && (
          <>
            <Link to="/">Início</Link>
            <Link to="/#data">Data e Hora</Link>
            <Link to="/#endereco">Endereço</Link>
            <Link to="/#presentes">Presentes</Link>
            <Link to="/#presenca">Confirme sua presença</Link>
          </>
        )}
      </MenuContent>
      {cartItemsCount > 0 && (
        <Cart className="no-mobile">
          <Link to="/carrinho" className="cart">
            <ShoppingCart size={32} />
            <span className="cart-items-count">{cartItemsCount}</span>
          </Link>
        </Cart>
      )}
    </Container>
  )
}
