import styled from 'styled-components'

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--red-500);
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  height: 48px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;

  &.secondary {
    background: var(--white);
    color: var(--red-500);
    border: 2px solid var(--red-500);
  }

  &:hover {
    filter: brightness(0.8);
    opacity: 1 !important;
  }

  .loader {
    margin-left: 16px;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--gray-500);
  }
`
