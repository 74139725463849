import { useEffect, useState } from 'react'

import { Check, Copy, SpinnerGap, X } from '@phosphor-icons/react'

import { type IPurchase } from '../../../@Types/IPurchase'
import { Loader } from '../../../components/Loader'
import { Container, Content } from './style'

interface IPurchaseStatusProps {
  purchase: IPurchase | undefined
}

export const PurchaseStatus: React.FC<IPurchaseStatusProps> = ({
  purchase,
}) => {
  const [loading, setLoading] = useState(true)
  const [paymentStatus, setPaymentStatus] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [copied, setCopied] = useState(false)

  function base64Decode(base64: string) {
    return `data:image/jpeg;base64,${base64}`
  }

  function copyQrCode() {
    navigator.clipboard
      .writeText(purchase?.paymentData.pix?.qrCode ?? '')
      .then(() => {
        setCopied(true)
      })
      .catch(console.error)
  }

  function copyBoleto() {
    navigator.clipboard
      .writeText(purchase?.paymentData.boleto?.digitableLine ?? '')
      .then(() => {
        setCopied(true)
      })
      .catch(console.error)
  }

  useEffect(() => {
    if (!purchase) {
      setLoading(true)
    } else {
      setPaymentStatus(purchase.paymentData.status)
      setPaymentMethod(purchase.paymentData.paymentMethodId)
      setLoading(false)
    }
  }, [purchase])

  if (loading) {
    return (
      <Container>
        <Content>
          <div className="loading">
            <p>Carregando...</p>
            <Loader color="#000" />
          </div>
        </Content>
      </Container>
    )
  }

  if (paymentStatus === 'approved') {
    return (
      <Container>
        <Content>
          <section className="status-section">
            {paymentMethod === 'credit_card' && (
              <h2>Compra no crédito aprovada!</h2>
            )}
            {paymentMethod === 'debit_card' && (
              <h2>Compra no débito aprovada!</h2>
            )}
            {paymentMethod === 'pix' && <h2>Compra no PIX aprovada!</h2>}
            {paymentMethod === 'bolbradesco' && (
              <h2>Compra no boleto aprovada!</h2>
            )}
            <span className="status-icon approved">
              <Check size={64} />
            </span>

            <p className="info">Muito obrigado pelo presente!</p>
          </section>
        </Content>
      </Container>
    )
  }

  if (paymentStatus === 'rejected' || paymentStatus === 'cancelled') {
    return (
      <Container>
        <Content>
          <section className="status-section">
            {paymentMethod === 'credit_card' && (
              <h2>Compra no crédito cancelada...</h2>
            )}
            {paymentMethod === 'debit_card' && (
              <h2>Compra no débito cancelada...</h2>
            )}
            {paymentMethod === 'pix' && <h2>Compra no PIX cancelada...</h2>}
            {paymentMethod === 'bolbradesco' && (
              <h2>Compra no boleto cancelada...</h2>
            )}
            <span className="status-icon rejected">
              <X size={64} />
            </span>

            <p className="info">
              Desculpe, ocorreu algum erro durante o pagamento.
              <br /> Em caso de algum problema, entre em contato com o noivo.
            </p>
          </section>
        </Content>
      </Container>
    )
  }

  if (
    (paymentStatus === 'authorized' ||
      paymentStatus === 'pending' ||
      paymentStatus === 'in_process') &&
    (paymentMethod === 'credit_card' || paymentMethod === 'debit_card')
  ) {
    return (
      <Container>
        <Content>
          <section className="status-section">
            {paymentMethod === 'credit_card' && (
              <h2>
                Compra no crédito
                <br /> em processamento...
              </h2>
            )}
            {paymentMethod === 'debit_card' && (
              <h2>
                Compra no débito
                <br /> em processamento...
              </h2>
            )}
            <span className={`status-icon ${paymentStatus}`}>
              <SpinnerGap size={64} />
            </span>
            {paymentStatus === 'pending' && (
              <p className="info">Pagamento pendente...</p>
            )}
            {(paymentStatus === 'in_process' ||
              paymentStatus === 'authorized') && (
              <p className="info">O pagamento está sendo processado...</p>
            )}
          </section>
        </Content>
      </Container>
    )
  }

  if (paymentMethod === 'pix' && purchase?.paymentData.pix) {
    return (
      <Container>
        <Content>
          <div className="pix">
            <h2>Faça o pagamento no Pix!</h2>
            <p>Escaneie o QR-Code</p>
            <img
              className="pix-qrcode"
              src={base64Decode(purchase.paymentData.pix.qrCodeImage)}
              alt="QR Code do Pix"
            />
            <div className="qrcode-copy-paste">
              <input
                type="text"
                value={purchase.paymentData.pix.qrCode}
                onChange={copyQrCode}
                onClick={copyQrCode}
              />
              ...
              <button type="button" onClick={copyQrCode}>
                <Copy size={32} />
              </button>
            </div>
            {copied && <p className="copied">Código copiado com sucesso.</p>}
            <p>
              Ou clique no link abaixo para fazer o pagamento do pix pelo
              mercado pago.
            </p>
            <p>
              <a
                href={purchase.paymentData.pix.ticketUrl}
                target="_blank"
                rel="noreferrer"
              >
                Pagamento no mercado pago
              </a>
            </p>
          </div>
        </Content>
      </Container>
    )
  }

  if (paymentMethod === 'bolbradesco' && purchase?.paymentData.boleto) {
    return (
      <Container>
        <Content>
          <div className="boleto">
            <h2>Faça o pagamento no boleto!</h2>
            <p>Copie o código de barras</p>
            <div className="boleto-copy-paste">
              <input
                type="text"
                value={purchase.paymentData.boleto.digitableLine}
                onChange={copyBoleto}
                onClick={copyBoleto}
              />
              ...
              <button type="button" onClick={copyBoleto}>
                <Copy size={32} />
              </button>
            </div>
            {copied && (
              <p className="copied">Código de barras copiado com sucesso.</p>
            )}
            <p>Ou clique no link abaixo para visualizar o boleto.</p>
            <p>
              <a
                href={purchase.paymentData.boleto.ticketUrl}
                target="_blank"
                rel="noreferrer"
              >
                Ver o boleto
              </a>
            </p>
          </div>
        </Content>
      </Container>
    )
  }

  return (
    <Container>
      <Content className="problem">
        <h2>Aconteceu algum problema...</h2>
        <p>Por favor, fale com o noivo.</p>
      </Content>
    </Container>
  )
}
