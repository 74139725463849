import { createContext, useContext, useEffect, useRef, useState } from 'react'

import { Loader } from '../../components/Loader'
import { LoadingPanel } from './styles'

interface ILoadingContextData {
  loading: boolean
  setLoading: (loading: boolean) => void
}

const LoadingContext = createContext<ILoadingContextData>(
  {} as unknown as ILoadingContextData,
)

interface ILoadingProviderProps {
  children: React.ReactNode
}

export const LoadingProvider: React.FC<ILoadingProviderProps> = ({
  children,
}) => {
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const loadingPanel = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (loading) {
      timeout.current && clearTimeout(timeout.current)
      timeout.current = null
      if (loadingPanel.current) {
        loadingPanel.current.style.zIndex = '999999'
        loadingPanel.current.style.pointerEvents = 'all'
      }
    } else {
      timeout.current && clearTimeout(timeout.current)
      timeout.current = null
      timeout.current = setTimeout(() => {
        if (loadingPanel.current) {
          loadingPanel.current.style.zIndex = '-999999'
          loadingPanel.current.style.pointerEvents = 'none'
        }
      }, 500)
    }
  }, [loading])

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
      <LoadingPanel
        ref={loadingPanel}
        className={loading ? 'active' : 'inactive'}
      >
        <p>Carregando...</p>
        <Loader />
      </LoadingPanel>
    </LoadingContext.Provider>
  )
}

export const useLoading = (): ILoadingContextData => {
  const context = useContext(LoadingContext)

  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider')
  }

  return context
}
