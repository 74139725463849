import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background-color: #fff5f5;

  h2 {
    padding-top: 50px;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    font-family: var(--font-cookie);
    color: var(--red-500);
    padding-bottom: 24px;
  }

  p {
    font-family: var(--font-cookie);
    font-size: 24px;
  }
`

export const TimerContainer = styled.div`
  border-image-slice: 182 182 182 182;
  border-image-width: 100px 100px 100px 100px;
  border-image-outset: 0px 0px 0px 0px;
  border-image-repeat: round round;
  border-image-source: url('/images/border.png');
  border-style: solid;
  height: 200px;
  width: 900px;

  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 32px 24px;
    gap: 8px;
    font-size: 32px;
    border-left: none;
    font-family: var(--font-cookie);

    span {
      width: 40px;
      text-align: right;
    }

    + div {
      border-left: 2px solid #f1d6b1;
    }
  }

  &.done {
    p {
      font-size: 48px !important ;
      white-space: nowrap;
    }
  }

  @media (max-width: 970px) {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 90%;
    padding: 24px 0;

    div {
      width: 80%;

      + div {
        border-left: 0;
        border-top: 2px solid #f1d6b1;
      }
    }
  }
`

export const DateContainer = styled.div`
  display: flex;
  padding: 50px 0;

  p {
    font-size: 32px;
    padding: 0 24px;
    font-family: var(--font-cookie);
    color: var(--red-500);

    + p {
      border-left: 2px solid #f1d6b1;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;

    p {
      text-align: center;

      + p {
        border-left: 0;
        margin-top: 8px;
      }
    }
  }
`
