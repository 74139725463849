import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { type IPaymentMethod } from '../../../@Types/IPaymentMethod'
import { type IPurchase } from '../../../@Types/IPurchase'
import { formatMoney } from '../../../services/utils/formatMoney'
import { getPaymentStatus } from '../../../services/utils/getPaymentStatus'
import { Container, Gift, Gifts, PurchaseInfo } from './styles'

interface IPurchaseProps {
  purchase: IPurchase
  paymentMethods: IPaymentMethod[]
}

export const Purchase: React.FC<IPurchaseProps> = ({
  purchase,
  paymentMethods,
}) => {
  const paymentMethodName = useMemo(() => {
    const paymentMethod = paymentMethods.find(
      (method) => method.id === purchase?.paymentData?.paymentMethodId,
    )
    if (
      !paymentMethod &&
      purchase?.paymentData?.paymentMethodId === 'credit_card'
    ) {
      return 'Cartão de crédito'
    }
    if (
      !paymentMethod &&
      purchase?.paymentData?.paymentMethodId === 'debit_card'
    ) {
      return 'Cartão de débito'
    }
    return paymentMethod?.name ?? 'Método de pagamento não encontrado'
  }, [paymentMethods, purchase])

  const status = useMemo(() => {
    return getPaymentStatus(
      purchase.paymentData.status,
      purchase.paymentData.statusDetail,
    )
  }, [purchase.paymentData.status, purchase.paymentData.statusDetail])

  const statusColor = useMemo(() => {
    if (
      ['approved', 'refunded', 'charged_back'].includes(
        purchase?.paymentData?.status,
      )
    )
      return '#06d6a0'
    if (
      ['pending', 'in_process', 'authorized', 'in_mediation'].includes(
        purchase?.paymentData?.status,
      )
    )
      return '#ffd166'
    if (['rejected', 'cancelled'].includes(purchase?.paymentData?.status))
      return '#ef476f'
    return '#118ab2'
  }, [purchase])

  return (
    <Container>
      <Gifts>
        <h3>Presentes</h3>
        <ul>
          {purchase.gifts.map((gift) => (
            <Gift key={gift.id}>
              <img
                src={gift.gift.imgURL ?? '/images/default.jpg'}
                alt={gift.gift.name}
              />
              <h4>{gift.gift.name}</h4>
              <p>
                {formatMoney(gift.unitPrice)} x{gift.quantity}
              </p>
            </Gift>
          ))}
        </ul>
      </Gifts>
      <PurchaseInfo>
        <h3>Informações do pagamento</h3>
        <section>
          <p>
            <strong>Total:</strong> {formatMoney(purchase.amount)}
          </p>
          <p>
            <strong>Forma de pagamento:</strong> {paymentMethodName}
          </p>
          {paymentMethodName.toUpperCase() !== 'PIX' && (
            <p>
              <strong>Parcelas:</strong> {purchase.paymentData.installments}
            </p>
          )}
          <p>
            <strong>Status do pagamento:</strong>{' '}
            <span style={{ color: statusColor }}></span> {status}
          </p>

          <p>
            <strong>Data da compra:</strong>{' '}
            {new Date(purchase.createdAt).toLocaleDateString()}
          </p>

          {(purchase.paymentData.paymentMethodId === 'pix' ||
            purchase.paymentData.paymentMethodId === 'bolbradesco') &&
            purchase.paymentData.status === 'pending' && (
              <p>
                <Link to={`/comprar-presentes/${purchase.id}`}>
                  <strong>Continuar com o pagamento</strong>
                </Link>
              </p>
            )}
        </section>
      </PurchaseInfo>
    </Container>
  )
}
