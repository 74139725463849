import { Container } from './styles'

export const AddressSection: React.FC = () => {
  return (
    <Container id="endereco">
      <div className="row1">
        <div className="address">
          <h3>O Endereço</h3>
          <p className="highlight">Espaço de Eventos Edelweiss</p>
          <p>
            R. Tércio Montanari, 55 <br /> Jardim Maua, Mauá - SP
            <br /> 09340-050
          </p>
          <p>
            <a
              href="https://maps.app.goo.gl/CUgF5ydG3oxXWmP8A"
              target="_blank"
              rel="noreferrer"
            >
              Ver no Google Maps
            </a>
          </p>
        </div>
        <div className="photo1"></div>
      </div>
      <div className="row2">
        <div className="photo2"></div>
        <div className="map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d456.77839280562426!2d-46.43116074780773!3d-23.66783373273607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce6ea827349b91%3A0x56d4d5a75d320fb!2sEspa%C3%A7o%20de%20Eventos%20Edelweiss!5e0!3m2!1spt-BR!2sbr!4v1705029572300!5m2!1spt-BR!2sbr"
            height="500"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </Container>
  )
}
