import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding-top: 124px;
  min-height: 100vh;
`

export const Content = styled.section`
  display: flex;
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;

  @media (max-width: 1300px) {
    max-width: 90vw;
  }
  @media (max-width: 1150px) {
    flex-direction: column;
    gap: 32px;
  }
`
