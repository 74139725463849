import styled from 'styled-components'

interface IContainerProps {
  isWhiteBackground?: boolean
}

export const Container = styled.label.withConfig({
  shouldForwardProp: (prop) => prop !== 'isWhiteBackground',
})<IContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    margin-bottom: 8px;
    font-size: 16px;
    color: ${(props) => (props.isWhiteBackground ? '#333' : 'initial')};
  }

  input {
    width: 100%;
    padding: 8px 16px;
    border-radius: 8px;
    border: 2px solid #ddd;
    outline: none;
    font-size: 16px;
    color: #000;
    height: 48px;

    &::placeholder {
      color: #999;
    }
  }

  .error {
    color: var(--red-500);
    font-size: 14px;
    margin-top: 8px;
    opacity: 0.8;
  }
`
