import styled from 'styled-components'

export const Container = styled.li`
  width: 100%;
  padding: 1.6rem 2.4rem;
  background-color: var(--white);
  border-radius: 0.8rem;
  color: #333;
  display: flex;
  align-items: center;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 0.8rem;
    margin-right: 1.6rem;
    object-fit: cover;
  }
  p {
    flex: 1;
  }
  .info {
    color: #888;
  }

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.8rem;

    button {
      width: 4rem;
      height: 4rem;
      border-radius: 0.8rem;
      border: none;
      background-color: #ff206e;
      color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`
