import { Link, useNavigate } from 'react-router-dom'

import { Footer } from '../../components/Footer'
import { Menu } from '../../components/Menu'
import { useCart } from '../../hooks/CartProvider'
import { formatMoney } from '../../services/utils/formatMoney'
import { CartItem } from './CartItem'
import { Container, CartList, PurchaseButton } from './styles'

export const Cart: React.FC = () => {
  const { cart, total, clearCart } = useCart()
  const totalFormatted = formatMoney(total)
  const navigate = useNavigate()

  function handlePurchase() {
    localStorage.setItem('@biafelipe:purchase', JSON.stringify(cart))
    clearCart()
    navigate('/comprar-presentes')
  }

  return (
    <Container>
      <Menu />

      <h2>Carrinho</h2>

      {cart.length === 0 && (
        <>
          <p>Você ainda não escolheu nenhum presente.</p>
          <Link to="/lista-de-presentes">Escolher presentes</Link>
        </>
      )}

      {!!cart.length && (
        <CartList>
          {cart.map((item) => (
            <CartItem
              key={item.gift.id}
              gift={item.gift}
              quantity={item.quantity}
            />
          ))}
        </CartList>
      )}

      {!!cart.length && (
        <div className="purchase-row">
          <p className="total">
            <strong>Total:</strong> {totalFormatted}
          </p>

          <PurchaseButton type="button" onClick={handlePurchase}>
            Comprar presentes
          </PurchaseButton>
        </div>
      )}

      <Footer />
    </Container>
  )
}
