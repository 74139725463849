import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button } from '../../../components/Button'
import { Input } from '../../../components/Input'
import { api } from '../../../services/api'
import { type IFormData, validation } from './form.validation'
import { Container } from './styles'

interface IIInsertCodeModalProps {
  onClose: () => void
  onCancel: () => void
}

export const InsertCodeModal: React.FC<IIInsertCodeModalProps> = ({
  onClose,
  onCancel,
}) => {
  const controls = useForm<IFormData>({ resolver: validation })
  const [loading, setLoading] = useState(false)

  async function handleSubmit(data: IFormData) {
    setLoading(true)
    try {
      const response = await api.post('guests/verification', data)
      if (!response.data.verified) {
        throw new Error('Código inválido')
      }
      localStorage.setItem('@biafelipe:code', data.code)
      api.defaults.headers.code = data.code
      onClose()
    } catch (error) {
      console.error(error)
      controls.setError('code', { message: 'Código inválido.' })
      setLoading(false)
      return
    }
    setLoading(false)
  }

  return (
    <Container>
      <h1>Por favor, insira o código do seu convite.</h1>
      <FormProvider {...controls}>
        <form onSubmit={controls.handleSubmit(handleSubmit)}>
          <Input
            name="code"
            label="Código"
            placeholder="Digite o código do convite"
          />
          <div className="row">
            <Button
              loading={loading}
              type="button"
              onClick={onCancel}
              appearance="secondary"
            >
              Cancelar
            </Button>
            <Button loading={loading} type="submit">
              Continuar
            </Button>
          </div>
        </form>
      </FormProvider>
    </Container>
  )
}
