import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 400px;

  h1 {
    font-size: 24px;
    margin-bottom: 24px;
    text-align: center;
  }

  .row {
    display: flex;
    gap: 16px;
    margin-top: 24px;

    button {
      flex: 1;
    }
  }
`
