import styled from 'styled-components'

export const Container = styled.header`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 64px;
  z-index: 100;

  background-color: var(--white);
  color: var(--red-500);

  > button {
    background: none;
    border: none;
    color: var(--white);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 1.4rem;
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    background-color: var(--red-500);
  }
`
