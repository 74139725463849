import { Link } from 'react-router-dom'

import { useAuth } from '../../../hooks/AuthProvider'
import { Container } from './styles'

export const AdminMenu: React.FC = () => {
  const { doLogout } = useAuth()

  return (
    <Container>
      <Link to="/admin">Home</Link>
      <Link to="/admin/users">Usuários</Link>
      <Link to="/admin/invites">Convites</Link>
      <Link to="/admin/gifts">Presentes</Link>

      <button type="button" onClick={doLogout}>
        Sair
      </button>
    </Container>
  )
}
