import styled from 'styled-components'

export const Container = styled.section`
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--black);
  font-weight: 500;
  padding: 50px 64px 100px;
  margin-top: auto;

  h2 {
    padding: 0 0 32px;
    font-weight: 500;
    font-family: var(--font-cookie);
    font-size: 4.8rem;
    text-align: center;

    strong {
      font-weight: 900;
      color: var(--red-500);
    }
  }

  a {
    color: var(--red-500);
    text-decoration: underline;
    opacity: 0.6;
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  .row {
    display: flex;
    gap: 64px;
    width: 100%;

    .col {
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: right;
      + .col {
        text-align: left;
      }
    }

    @media (max-width: 700px) {
      flex-direction: column-reverse;

      .col {
        text-align: center;

        + .col {
          text-align: center;
        }
      }
    }
  }

  @media (max-width: 430px) {
    padding: 50px 24px 100px;
  }
`
