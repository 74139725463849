import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

export interface IFormData {
  name: string
  price: string
  limit: number
}

export const validation = yupResolver(
  yup.object().shape({
    name: yup.string().required('Nome obrigatório'),
    price: yup.string().required('Preço obrigatório'),
    limit: yup.number().required('Limite obrigatório'),
  }),
)
