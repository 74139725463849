import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Box = styled.form`
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  min-width: 400px;
  gap: 16px;

  > h1 {
    font-size: 32px;
    color: #000;
    text-align: center;
  }
  > p {
    margin-bottom: 24px;
    text-align: center;
  }
`
