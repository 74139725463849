import { Container } from './styles'

interface ILodaerProps {
  scale?: number
  color?: string
  style?: React.CSSProperties
}

export const Loader: React.FC<ILodaerProps> = ({
  scale = 1,
  color = '#fff',
  style,
}) => {
  return (
    <Container
      className="loader"
      style={{ ...style, zoom: scale }}
      color={color}
    />
  )
}
