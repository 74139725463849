import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { type IGiftInCart } from '../../@Types/IGiftInCart'
import { type IPurchase } from '../../@Types/IPurchase'
import { Footer } from '../../components/Footer'
import { Menu } from '../../components/Menu'
import { api } from '../../services/api'
import { PurchasePayment } from './PurchasePayment'
import { PurchaseStatus } from './PurchaseStatus'
import { Container, Content } from './styles'
import { TotalPanel } from './TotalPanel'

const getGiftsInCart = () => {
  const cart = localStorage.getItem('@biafelipe:purchase')
  if (cart) {
    return JSON.parse(cart)
  }
  return []
}

const getTotal = (products: IGiftInCart[]) => {
  return products.reduce((acc, product) => {
    return acc + product.gift.price * product.quantity
  }, 0)
}

export const Purchase: React.FC = () => {
  const navigate = useNavigate()
  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const { purchaseId: hasPurchaseId } = useParams()
  const [purchaseId, setPurchaseId] = useState(hasPurchaseId ?? '')
  const [purchase, setPurchase] = useState<IPurchase>()
  const [step, setStep] = useState(hasPurchaseId ? 2 : 1)
  const [products, setProducts] = useState<IGiftInCart[]>(getGiftsInCart)
  const [total, setTotal] = useState(() => getTotal(products))
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!purchaseId && !products.length) {
      navigate('/lista-de-presentes')
    }
  }, [navigate, products, purchaseId])

  useEffect(() => {
    if (!purchaseId) return
    async function getPurchase() {
      const response = await api.get<IPurchase>(
        `/guests/purchases/payments/${purchaseId}`,
      )
      setPurchase(response.data)
      setProducts(response.data.gifts)
      setTotal(getTotal(response.data.gifts))
    }

    intervalRef.current = setInterval(() => {
      getPurchase().catch(console.error)
    }, 5000)

    setLoading(true)
    getPurchase()
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
      })

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [purchaseId])

  return (
    <Container>
      <Menu />

      <Content>
        {step === 1 && (
          <PurchasePayment
            setStep={setStep}
            setPurchaseId={setPurchaseId}
            products={products}
            total={total}
          />
        )}
        {step === 2 && <PurchaseStatus purchase={purchase} />}
        <TotalPanel
          purchase={purchase}
          products={products}
          total={total}
          loading={loading}
        />
      </Content>
      <Footer />
    </Container>
  )
}
