import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :root {
    --white: #fff;
    --black: #000;

    --gray-50: #f7f8fa;
    --gray-100: #e6e8eb;
    --gray-200: #afb2b1;
    --gray-500: #808080;
    --gray-800: #494d4b;
    --gray-900: #333333;
    --red-500: #cc0001;

    --background-gradient: linear-gradient(180deg, rgba(255, 183, 183, 0.37) 15.1%, #DEBBBB 100%);
    --text-gold-gradient: linear-gradient(
      to right,
      #9a7040 0,
      #cb9b51 12%,
      #f6e27a 45%,
      #ffec7b 50%,
      #f6e27a 55%,
      #cb9b51 88%,
      #9a7040 100%
    );
    --text-gold-gradient2: linear-gradient(
      to right,
      #9a7040 0,
      #cb9b51 12%,
      #e0c079 45%,
      #e0c079 50%,
      #e0c079 55%,
      #cb9b51 88%,
      #9a7040 100%
    );

    --font-cookie: 'Cookie', cursive;
    --font-greatvibes: 'Great Vibes', cursive;
    --font-windsong: 'WindSong', cursive;
    --font-inter: 'Inter', sans-serif;

    font-size: 62.5%;
  }

  @media (max-width: 720px) {
    :root {
      font-size: 57.5%;
    }
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    background-image: var(--background-gradient);
    background-repeat: no-repeat;
    min-height: 100vh;
  }

  body, input, textarea, button {
    font: 500 1.6rem Inter, sans-serif;
    color: var(--gray-900);
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`
