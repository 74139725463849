import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 0 64px;
`

export const Row = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 530px) {
    flex-direction: column;
  }
`
