import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { type IInvite } from '../../../../@Types/IInvite'
import { Button } from '../../../../components/Button'
import { ModalYesNo } from '../../../../components/ModalYesNo'
import { TitleContainer } from '../../../../components/TitleContainer'
import { useLoading } from '../../../../hooks/LoadingProvider'
import { useModal } from '../../../../hooks/ModalProvider'
import { api } from '../../../../services/api'
import { InviteRow } from './InviteRow'
import { Container, InviteList } from './styles'

export const Invites: React.FC = () => {
  const navigate = useNavigate()
  const [invites, setInvites] = useState<IInvite[]>([])
  const { openModal, closeModal } = useModal()
  const { loading, setLoading } = useLoading()
  const [search, setSearch] = useState('')

  const filteredInvites = useMemo(() => {
    return invites.filter((invite) =>
      `${invite.code} ${invite.name}`
        .toLowerCase()
        .includes(search.toLowerCase()),
    )
  }, [invites, search])

  const loadInvites = useCallback(async () => {
    setLoading(true)
    const response = await api.get('/invites')
    setInvites(response.data)
    setLoading(false)
  }, [setLoading])

  async function deleteInvite(invite: IInvite) {
    setLoading(true)
    await api.delete(`/invites/${invite.id}`)
    loadInvites().catch(console.error)
    closeModal()
  }

  function handleDelete(invite: IInvite) {
    openModal(
      <ModalYesNo
        title="Deletar Convite"
        message={`Tem certeza que quer deletar o convite de ${invite.name}?`}
        onClose={closeModal}
        onNo={closeModal}
        onYes={() => {
          deleteInvite(invite).catch(console.error)
        }}
      />,
    )
  }

  function handleEdit(invite: IInvite) {
    navigate(`/admin/invites/edit/${invite.id}`)
  }

  function handleAddInvite() {
    navigate('/admin/invites/add')
  }

  useEffect(() => {
    loadInvites().catch(console.error)
  }, [loadInvites])

  return (
    <Container>
      <TitleContainer>
        <h1>Convites</h1>

        <div className="actions">
          <input
            type="text"
            value={search}
            placeholder="Buscar por nome ou código..."
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
          <Button type="button" onClick={handleAddInvite}>
            Adicionar
          </Button>
        </div>
      </TitleContainer>

      <InviteList>
        {filteredInvites.map((invite) => (
          <InviteRow
            key={invite.id}
            invite={invite}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        ))}
        {invites.length === 0 && !loading && (
          <li className="empty">Nenhum convite cadastrado</li>
        )}
        {loading && <li className="empty">Carregando...</li>}
        {invites.length !== 0 && filteredInvites.length === 0 && (
          <li className="empty">Nenhum convite encontrado</li>
        )}
      </InviteList>
    </Container>
  )
}
