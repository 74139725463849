import { PencilSimple, Trash } from '@phosphor-icons/react'

import { type IUser } from '../../../../../@Types/IUser'
import { Container } from './styles'

interface IUserRowProps {
  user: IUser
  handleDelete: (user: IUser) => void
  handleEdit: (user: IUser) => void
}

export const UserRow: React.FC<IUserRowProps> = ({
  user,
  handleDelete,
  handleEdit,
}) => {
  return (
    <Container>
      <p>
        <strong>{user.name}</strong>
      </p>
      <p className="info">{user.email}</p>
      <div>
        <button
          type="button"
          style={{ background: '#4361ee' }}
          onClick={() => {
            handleEdit(user)
          }}
        >
          <PencilSimple size={24} />
        </button>
        <button
          type="button"
          onClick={() => {
            handleDelete(user)
          }}
        >
          <Trash size={24} />
        </button>
      </div>
    </Container>
  )
}
