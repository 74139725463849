import { useFormContext } from 'react-hook-form'

import { Check, X } from '@phosphor-icons/react'

import { Container } from './styles'

interface IInputConfirmedProps {
  label: string
  name: string
  isWhiteBackground?: boolean
  disabled?: boolean
}

export const InputConfirmed: React.FC<IInputConfirmedProps> = ({
  label,
  name,
  isWhiteBackground,
  disabled,
}) => {
  const { watch, setValue } = useFormContext()

  const value = watch(name)

  const handleConfirm = () => {
    if (disabled) return
    if (value === true) {
      setValue(name, null)
    } else {
      setValue(name, true)
    }
  }

  const handleNoConfirm = () => {
    if (disabled) return
    if (value === false) {
      setValue(name, null)
    } else {
      setValue(name, false)
    }
  }

  return (
    <Container isWhiteBackground={isWhiteBackground} disabled={disabled}>
      <span>{label}</span>
      <div className="input-content">
        <button
          type="button"
          onClick={handleConfirm}
          className={`confirm${value === true ? ' active' : ''}`}
        >
          <Check size={32} weight="bold" />
        </button>
        <button
          type="button"
          onClick={handleNoConfirm}
          className={`no-confirm${value === false ? ' active' : ''}`}
        >
          <X size={32} weight="bold" />
        </button>
      </div>
    </Container>
  )
}
