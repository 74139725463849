import { useEffect } from 'react'

import { Footer } from '../../components/Footer'
import { Menu } from '../../components/Menu'
import { AddressSection } from './AddressSection'
import { DateSection } from './DateSection'
import { GiftsSection } from './GiftsSection'
import { HeroSection } from './HeroSection'
import { PresenceSection } from './PresenceSection'
import { Container } from './styles'

export const Home: React.FC = () => {
  useEffect(() => {
    if (window.location.hash) {
      setTimeout(() => {
        const id = window.location.hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 100)
    }
  }, [])

  return (
    <Container>
      <Menu />
      <HeroSection />
      <DateSection />
      <AddressSection />
      <GiftsSection />
      <PresenceSection />
      <Footer />
    </Container>
  )
}
