import { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '../../../../components/Button'
import { Input } from '../../../../components/Input'
import { TitleContainer } from '../../../../components/TitleContainer'
import { api } from '../../../../services/api'
import {
  type IFormData,
  dataValidation,
  passwordValidation,
  type IPasswordFormData,
} from './form.validation'
import { Container, Form } from './styles'

export const EditUser: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const dataControls = useForm<IFormData>({ resolver: dataValidation })
  const passwordControls = useForm<IPasswordFormData>({
    resolver: passwordValidation,
  })

  const handleBack = () => {
    navigate('/admin/users')
  }

  async function handleSubmit(data: IFormData) {
    if (!id) return
    await api.patch(`/users/${id}`, data)
    navigate('/admin/users')
  }

  async function handleSubmitPassword(data: IPasswordFormData) {
    if (!id) return
    await api.patch(`/users/${id}`, data)
    navigate('/admin/users')
  }

  const getUser = useCallback(async () => {
    if (!id) return
    const response = await api.get(`/users/${id}`)
    dataControls.reset(response.data)
  }, [dataControls, id])

  useEffect(() => {
    getUser().catch(console.error)
  }, [getUser, id])

  return (
    <Container>
      <TitleContainer>
        <h1>Atualizar Usuário</h1>
        <Button type="button" style={{ opacity: 0.5 }} onClick={handleBack}>
          Voltar
        </Button>
      </TitleContainer>

      <FormProvider {...dataControls}>
        <Form
          onSubmit={dataControls.handleSubmit(handleSubmit)}
          autoComplete="off"
        >
          <Input name="name" label="Nome" type="text" placeholder="Nome" />
          <Input
            name="email"
            label="E-mail"
            type="email"
            placeholder="E-mail"
          />
          <Button type="submit">Atualizar</Button>
        </Form>
      </FormProvider>
      <TitleContainer>
        <h2>Alterar Senha</h2>
      </TitleContainer>
      <FormProvider {...passwordControls}>
        <Form
          onSubmit={passwordControls.handleSubmit(handleSubmitPassword)}
          autoComplete="off"
        >
          <Input
            name="password"
            label="Senha Atual"
            type="password"
            placeholder="Senha atual"
          />
          <Input
            name="newPassword"
            label="Nova Senha"
            type="password"
            placeholder="Senha"
          />
          <Input
            name="passwordConfirmation"
            label="Confirmação de senha"
            type="password"
            placeholder="Confirmação da senha"
          />
          <Button type="submit">Alterar senha</Button>
        </Form>
      </FormProvider>
    </Container>
  )
}
