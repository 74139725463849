import { Minus, Plus, Trash } from '@phosphor-icons/react'

import { type IGift } from '../../../@Types/IGift'
import { ModalYesNo } from '../../../components/ModalYesNo'
import { useCart } from '../../../hooks/CartProvider'
import { useModal } from '../../../hooks/ModalProvider'
import { formatMoney } from '../../../services/utils/formatMoney'
import { Container } from './styles'

interface ICartItemProps {
  gift: IGift
  quantity: number
}

export const CartItem: React.FC<ICartItemProps> = ({ gift, quantity }) => {
  const { removeProduct, changeProductQuantity } = useCart()
  const { openModal, closeModal } = useModal()

  const price = formatMoney(gift.price)
  const subTotal = formatMoney(gift.price * quantity)

  const handleRemoveProduct = () => {
    openModal(
      <ModalYesNo
        title={`Remover o item ${gift.name} do carrinho?`}
        message="Tem certeza que deseja remover este item do carrinho? Poxa, iamos adorar ganhar esse presente!"
        onNo={() => {
          closeModal()
        }}
        onYes={() => {
          removeProduct(gift.id)
          closeModal()
        }}
        onClose={closeModal}
      />,
    )
  }

  const handleDecreaseQuantity = () => {
    if (quantity === 1) {
      handleRemoveProduct()
    } else {
      changeProductQuantity(gift.id, quantity - 1)
    }
  }

  return (
    <Container>
      <div className="image">
        <img src={gift.imgURL ?? '/images/default.jpg'} alt={gift.name} />
      </div>
      <p>
        <strong>{gift.name}</strong>
      </p>
      <div className="quantity">
        <button type="button" onClick={handleDecreaseQuantity}>
          <Minus size={24} />
        </button>
        <input
          type="number"
          value={quantity}
          onChange={(e) => {
            changeProductQuantity(gift.id, Number.parseInt(e.target.value) || 1)
          }}
        />
        <button
          type="button"
          onClick={() => {
            changeProductQuantity(gift.id, quantity + 1)
          }}
        >
          <Plus size={24} />
        </button>
      </div>

      <p className="info">
        <strong>Valor:</strong>
        <span>{price}</span>
      </p>
      <p>
        <strong>Subtotal:</strong>
        <span>{subTotal}</span>
      </p>

      <button type="button" onClick={handleRemoveProduct}>
        <Trash size={32} />
      </button>
    </Container>
  )
}
