import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  flex: 1;
  padding: 24px;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 64px;
  min-height: 400px;

  @media (max-width: 1150px) {
    margin-bottom: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: 100%;
  margin: 0 auto;

  .info {
    margin-top: 16px;
    text-align: center;
  }

  .status-section {
    h2 {
      text-align: center;
      font-size: 24px;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 32px auto 0;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    color: #fff;

    &.approved {
      background-color: #4caf50;
    }
    &.pending {
      background-color: #ffeb3b;
      animation: ${rotate} 2s infinite linear;
    }
    &.in_process {
      background-color: #ff9800;
      animation: ${rotate} 2s infinite linear;
    }
    &.rejected {
      background-color: #f44336;
    }
    &.cancelled {
      background-color: #9e9e9e;
    }
    &.refunded {
      background-color: #9e9e9e;
    }
    &.charged_back {
      background-color: #9e9e9e;
    }
  }

  h2 {
    text-align: center;
  }

  .loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    p {
      margin-bottom: 16px;
    }
  }

  .problem,
  .boleto {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .boleto,
  .pix {
    img {
      width: 100%;
      max-width: 300px;
      display: block;
      margin: 0 auto;
    }

    .boleto-copy-paste,
    .qrcode-copy-paste {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 16px;
      position: relative;
      border: 1px solid #ccc;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      input {
        height: 56px;
        padding: 0 5px 0 16px;
        text-align: center;
        background: none;
        border: none;
        width: 100%;
        outline: none;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 56px;
        min-height: 56px;
        border: none;
        border-radius: 8px;
        color: #333;
        cursor: pointer;
        background: none;
      }
    }

    p {
      font-size: 16px;
      color: #333;
      text-align: center;
      margin-top: 16px;

      &.copied {
        color: #06d6a0;
      }

      a {
        color: var(--red-500);
        text-decoration: underline;
        opacity: 0.8;
        transition: opacity 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`
