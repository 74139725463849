import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useLoading } from '../../../hooks/LoadingProvider'
import { api } from '../../../services/api'
import { Container, Content } from './styles'

const getCode = () => {
  const code = localStorage.getItem('@biafelipe:code')
  return code ?? ''
}

export const PresenceSection: React.FC = () => {
  const navigate = useNavigate()
  const [code, setCode] = useState(getCode)
  const [error, setError] = useState(false)
  const { setLoading } = useLoading()

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault()
      setError(false)
      setLoading(true)

      if (!code) {
        setError(true)
        setLoading(false)
        return
      }

      await api.post('guests/verification', { code })

      setLoading(false)
      navigate(`/confirmacao/${code}`)
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  return (
    <Container id="presenca">
      <h2>Confirme sua presença!</h2>

      <Content>
        <p>
          <strong>Digite o seu código aqui</strong>
        </p>
        <span>
          Caso você não tenha recebido o seu código, entre em contato com a
          noiva ou o noivo.
        </span>

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Código"
            value={code}
            onChange={(e) => {
              setCode(e.target.value)
            }}
          />
          {error && <p className="error">Digite um código válido.</p>}
          <button type="submit">Confirmar</button>
        </form>
      </Content>
    </Container>
  )
}
