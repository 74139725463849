import { useNavigate } from 'react-router-dom'

import { type IGift } from '../../@Types/IGift'
import { useCart } from '../../hooks/CartProvider'
import { useModal } from '../../hooks/ModalProvider'
import { formatMoney } from '../../services/utils/formatMoney'
import { AddCartModal } from './AddCartModal'
import { Container } from './styles'
interface IGiftProps {
  gift: IGift
}

export const Gift: React.FC<IGiftProps> = ({ gift }) => {
  const { addProduct, cart } = useCart()
  const { openModal, closeModal } = useModal()
  const navigate = useNavigate()

  const formattedGiftPrive = formatMoney(gift.price)
  const canGiftMore =
    gift.totalPurchases === 0 ||
    gift.limit <= 0 ||
    gift.totalPurchases < gift.limit

  const handleAddToCart = () => {
    if (!canGiftMore) return

    if (cart.some((item) => item.gift.id === gift.id)) {
      navigate('/carrinho')
      return
    }

    addProduct({ gift, quantity: 1 })
    openModal(
      <AddCartModal
        gift={gift}
        onClose={() => {
          closeModal()
        }}
        onNavigateToCart={() => {
          closeModal()
          navigate('/carrinho')
        }}
      />,
    )
  }

  return (
    <Container className={!canGiftMore ? 'gifted-all' : ''}>
      <img src={gift.imgURL ?? '/images/default.jpg'} alt={gift.name} />
      <h4>{gift.name}</h4>
      <span>{formattedGiftPrive}</span>
      {gift.totalPurchases >= 1 && canGiftMore && (
        <span className="gifted">Presenteado: {gift.totalPurchases}x</span>
      )}

      {!cart.some((item) => item.gift.id === gift.id) ? (
        <button type="button" onClick={handleAddToCart}>
          Presentear
        </button>
      ) : (
        <button type="button" className="secondary" onClick={handleAddToCart}>
          No carrinho
        </button>
      )}

      {!canGiftMore && <span className="gifted-all">Presenteado</span>}
    </Container>
  )
}
