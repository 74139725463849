import { Hooks } from './hooks'
import { Router } from './router'
import { GlobalStyles } from './styles/global'

function App() {
  return (
    <>
      <GlobalStyles />
      <Hooks>
        <Router />
      </Hooks>
    </>
  )
}

export default App
