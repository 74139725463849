import { useAuth } from '../../../hooks/AuthProvider'
import { Container } from './styles'

export const AdminHome: React.FC = () => {
  const { user } = useAuth()

  const userFirstName = user?.name.split(' ')[0] ?? 'Usuário'

  return (
    <Container>
      <h1>Olá, {userFirstName}!</h1>
    </Container>
  )
}
