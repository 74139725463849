import styled, { css } from 'styled-components'
interface IContainerProps {
  isWhiteBackground?: boolean
  disabled?: boolean
}

export const Container = styled.section.withConfig({
  shouldForwardProp: (prop) =>
    ['isWhiteBackground', 'disabled'].some((item) => item !== prop),
})<IContainerProps>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}

  span {
    margin-bottom: 8px;
    font-size: 16px;
    color: ${(props) => (props.isWhiteBackground ? '#333' : 'initial')};
  }

  .input-content {
    display: flex;
    gap: 8px;
  }

  input {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }
  button {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    color: #f5f5f5;
    opacity: 0.5;
    cursor: pointer;
    border: none;

    &.confirm {
      background-color: #3bb273;
    }

    &.no-confirm {
      background-color: #e15554;
    }

    &.active {
      opacity: 1;
    }
  }
`
