import styled from 'styled-components'

export const Container = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.85);
  width: 90vw;
  position: fixed;
  top: 3.2rem;
  right: 50%;
  transform: translateX(50%);
  padding: 0 3.2rem;
  border-radius: 0.8rem;
  z-index: 99;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.2);
  transition: all 0.2s;

  h1 {
    font-size: 2.4rem;
    font-weight: 700;
    color: #000;
    text-align: center;
    font-family: var(--font-greatvibes);
    /* color: var(--red-500); */
    background-image: var(--text-gold-gradient2);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    padding-right: 1.6rem;
  }

  a {
    color: #000;
    padding: 1.6rem 2.4rem;
    opacity: 0.8;
    transition: opacity 0.2s;

    &:hover,
    &.active {
      opacity: 1;
    }
  }

  @media (max-width: 870px) {
    padding: 0;
    width: 90vw;
    min-width: 90vw;
    max-width: 90vw;
    height: fit-content;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 1);
    right: 50%;
    transform: translateX(50%);

    &.open {
    }
  }

  @media (max-width: 530px) {
    &.has-cart {
      h1 {
        font-size: 2rem;
      }
    }
  }
`

export const Cart = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.8rem;
    height: 4.8rem;
    padding: 0;
  }
  .cart-items-count {
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 1rem;
    width: 1.6rem;
    height: 1.6rem;
    background-color: var(--red-500);
    border-radius: 50%;
  }

  @media (max-width: 870px) {
    position: initial;
    transform: translateY(0);

    &.no-mobile {
      display: none;
    }
  }
`

interface IMenuContentProps {
  open: boolean
}

export const MenuContent = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'open',
})<IMenuContentProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: height 0.2s;

  @media (max-width: 870px) {
    flex-direction: column;
    height: ${(props) => (props.open ? '340px' : '0px')};
    overflow: hidden;

    a {
      width: 100%;
      text-align: center;
      padding: 2.4rem;
    }
  }
`

export const MenuMobile = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.6rem;
  justify-content: space-between;
  height: 80px;
  transition: all 0.2s;

  @media (min-width: 871px) {
    display: none;
  }
`

export const ToggleMenu = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  border: 0;
  background-color: transparent;
  box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0);
  border-radius: 50%;
  transition: all 0.2s;

  &:hover {
    background-color: #f3f3f3;
    box-shadow: 0 0 0.8rem rgba(0, 0, 0, 0.25);
  }
`
