import { createContext, useContext, useEffect, useState } from 'react'

import { jwtDecode } from 'jwt-decode'

import { api } from '../services/api'

interface IUser {
  id: string
  name: string
  email: string
}

interface IDoLoginParams {
  email: string
  password: string
}
interface IAuthProviderData {
  token?: string
  user?: IUser
  isLogged: boolean
  doLogin: (data: IDoLoginParams) => Promise<void>
  doLogout: () => void
}

const AuthContext = createContext<IAuthProviderData>({} as any)

interface IAuthProviderProps {
  children: React.ReactNode
}

function getUserFromLocalStorage() {
  const user = localStorage.getItem('@biafelipe:user')
  if (user) {
    return JSON.parse(user)
  }
  return undefined
}

function getTokenFromLocalStorage() {
  const token = localStorage.getItem('@biafelipe:token')
  if (token) {
    return token
  }
  return undefined
}

function getIsLoggedFromLocalStorage() {
  const token = localStorage.getItem('@biafelipe:token')
  if (token) {
    const decoded = jwtDecode(token)
    if (!decoded?.exp) return false
    const now = Date.now()
    const isLogged = decoded.exp * 1000 > now
    return isLogged
  }
  return false
}

export const AuthProvider: React.FC<IAuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | undefined>(
    getTokenFromLocalStorage,
  )
  const [user, setUser] = useState<IUser | undefined>(getUserFromLocalStorage)
  const [isLogged, setIsLogged] = useState(getIsLoggedFromLocalStorage)

  const doLogin = async (data: IDoLoginParams) => {
    const response = await api.post('/auth/login', data)
    const { accessToken, user } = response.data as {
      accessToken: string
      user: IUser
    }
    localStorage.setItem('@biafelipe:token', accessToken)
    localStorage.setItem('@biafelipe:user', JSON.stringify(user))
    setToken(accessToken)
    setUser(user)
    setIsLogged(true)
    if (accessToken) {
      api.defaults.headers.Authorization = `Bearer ${accessToken}`
    }
  }
  const doLogout = () => {
    localStorage.removeItem('@biafelipe:token')
    localStorage.removeItem('@biafelipe:user')
    setToken(undefined)
    setUser(undefined)
    setIsLogged(false)
  }

  useEffect(() => {
    function verifyToken() {
      if (token) {
        const decoded = jwtDecode(token)
        if (!decoded?.exp) return
        const now = Date.now()
        const isLogged = decoded.exp * 1000 > now
        if (!isLogged) {
          doLogout()
        }
      }
    }

    verifyToken()
    const interval = setInterval(verifyToken, 5000)
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`
    }

    return () => {
      clearInterval(interval)
    }
  }, [token])

  return (
    <AuthContext.Provider value={{ token, user, isLogged, doLogin, doLogout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}
