import { Container } from './style'

interface ITileContainerProps {
  children: React.ReactNode
  style?: React.CSSProperties
}

export const TitleContainer: React.FC<ITileContainerProps> = ({
  children,
  style,
}) => {
  return <Container style={style}>{children}</Container>
}
