import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 124px;

  > h2 {
    font-size: 3.2rem;
    text-align: center;
    margin: 0 auto 32px;
    width: 90%;
  }
  > p {
    font-size: 2rem;
    text-align: center;
    margin: 16px auto 0;
    width: 90%;
    span {
      font-size: 1.6rem;
    }
  }

  .explain {
    margin: 0 auto 64px;
    max-width: 600px;
    width: 80%;
  }
`

export const GiftList = styled.ul`
  display: flex;
  gap: 24px;
  margin: 0 auto;
  padding: 64px 0;
  row-gap: 64px;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  max-width: 1000px;

  @media (max-width: 1070px) {
    width: 80%;
  }
`
