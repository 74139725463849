import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 124px;
  min-height: 100vh;

  h2 {
    font-size: 3.2rem;
    text-align: center;
    width: 90%;
    margin: 0 auto 32px;
  }

  > p {
    font-size: 2rem;
    text-align: center;
    width: 90%;
    margin: 16px auto 0;
  }

  > a {
    display: block;
    margin-top: 16px;
    text-align: center;
    font-size: 1.8rem;
    color: var(--red-500);
    text-decoration: underline;
  }

  .purchase-row {
    width: 100%;
    max-width: 80vw;
    margin: 64px auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    align-items: flex-end;

    .total {
      width: fit-content;
      font-size: 1.8rem;

      strong {
        display: block;
        font-size: 2.4rem;
      }
    }

    @media (max-width: 870px) {
      align-items: center;
      text-align: center;

      button {
        margin: 0 auto;
      }
    }
  }
`

export const CartList = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 80vw;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 1100px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const PurchaseButton = styled.button`
  background-color: var(--red-500);
  color: var(--white);
  padding: 16px 24px;
  border-radius: 8px;
  text-align: center;
  transition: filter 0.2s;
  max-width: 200px;
  margin: 0 0 64px auto;
  border: none;

  &:hover {
    filter: brightness(0.9);
  }
`
