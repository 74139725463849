import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { type IGift } from '../../../@Types/IGift'
import { Gift } from '../../../components/Gift'
import { Loader } from '../../../components/Loader'
import { api } from '../../../services/api'
import { Container, GiftList } from './styles'

export const GiftsSection: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [giftsList, setGiftsList] = useState<IGift[]>([])

  useEffect(() => {
    async function getGiftsList() {
      try {
        setLoading(true)
        const response = await api.get<IGift[]>('/public/gifts')

        setGiftsList(response.data)
        setLoading(false)
      } catch {
        console.error('Erro ao buscar lista de presentes')
      }
    }

    getGiftsList().catch(console.error)
  }, [])

  return (
    <Container id="presentes">
      <h2>Lista de Presentes</h2>
      <p>Quer nos dar algum presente? Escolha algum da lista abaixo!</p>

      {!loading && (
        <GiftList>
          {giftsList.map((gift) => (
            <Gift key={gift.id} gift={gift} />
          ))}
        </GiftList>
      )}

      {loading && (
        <Loader style={{ marginTop: '56px' }} color="var(--red-500)" />
      )}

      <Link to="/lista-de-presentes" className="gift-list-button">
        Ver toda a lista
      </Link>
    </Container>
  )
}
