import { useEffect, useState } from 'react'

import { type IGift } from '../../@Types/IPurchase'
import { Footer } from '../../components/Footer'
import { Gift } from '../../components/Gift'
import { Menu } from '../../components/Menu'
import { useLoading } from '../../hooks/LoadingProvider'
import { api } from '../../services/api'
import { Container, GiftList } from './styles'

export const Gifts: React.FC = () => {
  const [gifts, setGifts] = useState<IGift[]>([])
  const { setLoading } = useLoading()

  useEffect(() => {
    async function getGifts() {
      setLoading(true)
      const response = await api.get('public/gifts/all')
      setGifts(response.data)
      setLoading(false)
    }

    getGifts().catch((error) => {
      console.error(error)
      setLoading(false)
    })
  }, [setLoading])

  return (
    <Container>
      <Menu />
      <h2>Lista de presentes</h2>
      <p>Quer nos dar algum presente? Escolha algum da lista abaixo!</p>
      <p>
        <span>
          Os noivos receberão o valor em dinheiro, que será destinado ao
          presente escolhido.
        </span>
      </p>
      <GiftList>
        {gifts.map((gift) => (
          <Gift key={gift.id} gift={gift} />
        ))}
      </GiftList>

      <p className="explain">
        <strong>
          <span>Por que comprar os presentes aqui?</span>
        </strong>
        <br />
        <span>
          Assim evitamos presentes repetidos, trocas, e dessa forma, você pode
          ajudar os noivos ainda mais! Mas não se se preocupe, se você preferir,
          pode entregar qualquer presente pessoalmente.
        </span>
      </p>
      <Footer />
    </Container>
  )
}
