import { Container } from './styles'

export const Footer: React.FC = () => {
  return (
    <Container>
      <h2>
        Casamento da <strong>Bia</strong> e do <strong>Felipe</strong>
      </h2>
      <div className="row">
        <div className="col">
          <h3>Endereço</h3>
          <p>Espaço de Eventos Edelweiss</p>
          <p>R. Tércio Montanari, 55</p>
          <p>Jardim Maua, Mauá - SP</p>
          <p>09340-050</p>
        </div>
        <div className="col">
          <h3>Contatos</h3>
          <p>
            Contato noivo:{' '}
            <a
              href="https://wa.me/+5511950402518"
              target="_blank"
              rel="noreferrer"
            >
              (11) 95040-2518
            </a>
          </p>
          <p>
            Contato noiva:{' '}
            <a
              href="https://wa.me/+5511941598590"
              target="_blank"
              rel="noreferrer"
            >
              (11) 94159-8590
            </a>
          </p>
          <p>
            E-mail noivo:{' '}
            <a href="mailto:contato@felipedslima.com.br">
              contato@felipedslima.com.br
            </a>
          </p>
        </div>
      </div>
    </Container>
  )
}
