import { useEffect, useRef } from 'react'

import {
  Container,
  Cover,
  Photo,
  AlbumContainer,
  PhotoImage,
  PhotoContainer,
} from './styles'

const felipePhotos = [
  { image: '/images/felipe/felipe1.jpg', alt: 'Felipe' },
  { image: '/images/felipe/felipe2.jpg', alt: 'Felipe e Bia' },
  { image: '/images/felipe/felipe3.jpg', alt: 'Felipe e Bia' },
]
const biaPhotos = [
  { image: '/images/bia/bia1.jpg', alt: 'Bia' },
  { image: '/images/bia/bia2.jpg', alt: 'Bia e Felipe' },
  { image: '/images/bia/bia3.jpg', alt: 'Bia e Felipe' },
]
export const HeroSection: React.FC = () => {
  const photoBiaRef = useRef<HTMLDivElement>(null)
  const photoFelipeRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const photoBia = photoBiaRef.current

    if (!photoBia) return

    const biaInterval = setInterval(() => {
      const current = Number(photoBia.dataset.current)
      const next = current + 1 >= biaPhotos.length ? 0 : current + 1

      photoBia.dataset.current = String(next)

      photoBia.dataset.current = String(next)
      for (let i = 0; i < photoBia.children.length; i++) {
        const child = photoBia.children[i] as HTMLDivElement
        child.style.top = `-${next * photoBia.clientHeight}px`
      }
    }, 5000)

    return () => {
      clearInterval(biaInterval)
    }
  }, [])

  useEffect(() => {
    const photoFelipe = photoFelipeRef.current

    if (!photoFelipe) return

    const felipeInterval = setInterval(() => {
      setTimeout(() => {
        const current = Number(photoFelipe.dataset.current)
        const next = current + 1 >= biaPhotos.length ? 0 : current + 1

        photoFelipe.dataset.current = String(next)
        for (let i = 0; i < photoFelipe.children.length; i++) {
          const child = photoFelipe.children[i] as HTMLDivElement
          child.style.top = `-${next * photoFelipe.clientHeight}px`
        }
      }, 200)
    }, 5000)

    return () => {
      clearInterval(felipeInterval)
    }
  }, [])

  return (
    <Container id="home">
      <Cover>
        <AlbumContainer>
          <PhotoContainer>
            <Photo className="photo1">
              <PhotoImage ref={photoBiaRef} data-current={0}>
                {biaPhotos.map((photo) => (
                  <img key={photo.image} src={photo.image} alt={photo.alt} />
                ))}
              </PhotoImage>
              <h2 title="Bia">Bia</h2>
            </Photo>
            <img
              className="photo-decoration"
              src="/images/photo-flowers.png"
              alt="Flores de cerejeira."
            />
          </PhotoContainer>
          <div className="and">&</div>
          <PhotoContainer>
            <Photo className="photo2">
              <PhotoImage ref={photoFelipeRef} data-current={0}>
                {felipePhotos.map((photo) => (
                  <img key={photo.image} src={photo.image} alt={photo.alt} />
                ))}
              </PhotoImage>
              <h2 title="Felipe">Felipe</h2>
            </Photo>
          </PhotoContainer>
        </AlbumContainer>
      </Cover>
    </Container>
  )
}
