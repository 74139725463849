import { PencilSimple, Trash } from '@phosphor-icons/react'

import { type IGift } from '../../../../../@Types/IPurchase'
import { Container } from './styles'

interface IUserRowProps {
  gift: IGift
  handleDelete: (user: IGift) => void
  handleEdit: (user: IGift) => void
}

export const GiftRow: React.FC<IUserRowProps> = ({
  gift,
  handleDelete,
  handleEdit,
}) => {
  const formatedPrice = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(gift.price)

  return (
    <Container>
      <img src={gift.imgURL ?? '/images/default.jpg'} alt="Default" />
      <p>
        <strong>{gift.name}</strong>
      </p>
      <p className="info">
        <strong>Preço:</strong> <br />
        {formatedPrice}
      </p>
      <p className="info">
        <strong>Limite:</strong> <br />
        {gift.limit}
      </p>

      <p className="info">
        <strong>Total de Compras:</strong> <br />
        {gift.totalPurchases}
      </p>

      <div>
        <button
          type="button"
          style={{ background: '#4361ee' }}
          onClick={() => {
            handleEdit(gift)
          }}
        >
          <PencilSimple size={24} />
        </button>
        <button
          type="button"
          onClick={() => {
            handleDelete(gift)
          }}
        >
          <Trash size={24} />
        </button>
      </div>
    </Container>
  )
}
