import { useEffect, useState } from 'react'

import { Container } from './styles'

interface IImageInputProps {
  file: File | null
  setFile: (file: File) => void
  initialPreview?: string
}

export const InputImage: React.FC<IImageInputProps> = ({
  file,
  setFile,
  initialPreview = '/images/default.jpg',
}) => {
  const [preview, setPreview] = useState<string>(initialPreview)

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]

    if (file) {
      setFile(file)
      setPreview(URL.createObjectURL(file))
    }
  }

  useEffect(() => {
    if (file) {
      const prev = URL.createObjectURL(file)
      setPreview(prev ?? initialPreview)
    } else {
      setPreview(initialPreview)
    }
  }, [file, initialPreview])

  return (
    <Container>
      <span>Imagem</span>
      <input type="file" onChange={handleUpload} />
      <div className="preview">
        <img src={preview} alt="Preview" />
      </div>
    </Container>
  )
}
