import { Button, Container } from './styles'
interface IModalYesNoProps {
  title: string
  message: string

  onClose: () => void
  onYes: () => void
  onNo: () => void
}

export const ModalYesNo: React.FC<IModalYesNoProps> = ({
  title,
  message,
  onNo,
  onYes,
}) => {
  return (
    <Container>
      <h2>{title}</h2>
      <p>{message}</p>
      <div className="buttons">
        <Button type="button" color="#4361ee" onClick={onYes}>
          Sim
        </Button>
        <Button type="button" color="#ff206e" onClick={onNo}>
          Não
        </Button>
      </div>
    </Container>
  )
}
