import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 400px;

  h2 {
    color: #000;
    font-size: 32px;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    color: #333;
    margin-bottom: 16px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
`

export const Button = styled.button`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 8px;
  border-radius: 8px;
  border: none;
  background: ${(props) => props.color};
  border: 2px solid ${(props) => props.color};
  color: #fff;
`
