import { useMemo } from 'react'

import { PencilSimple, Trash } from '@phosphor-icons/react'

import { type IInvite } from '../../../../../@Types/IInvite'
import { Container } from './styles'

interface IInviteRowProps {
  invite: IInvite
  handleDelete: (user: IInvite) => void
  handleEdit: (user: IInvite) => void
}

export const InviteRow: React.FC<IInviteRowProps> = ({
  invite,
  handleDelete,
  handleEdit,
}) => {
  const giftsAmount = useMemo(() => {
    const length = invite.purchases.reduce(
      (acc, purchase) =>
        purchase.gifts.reduce((acc2, gift) => gift.quantity, 0) + acc,
      0,
    )
    return length
  }, [invite.purchases])
  const giftsTotal = useMemo(() => {
    const total = invite.purchases.reduce(
      (acc, purchase) => purchase.amount + acc,
      0,
    )
    return new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format(total)
  }, [invite.purchases])

  const invitesConfirmed = useMemo(() => {
    const size = invite.guests.reduce(
      (acc, guest) => (guest.confirmed ? 1 : 0) + acc,
      0,
    )
    console.log(size, invite.guests)
    return size
  }, [invite.guests])

  return (
    <Container>
      <p style={{ flex: 2 }}>
        <strong>{invite.name}</strong>
      </p>
      <p className="info">
        <strong>Código:</strong>
        <br /> {invite.code}
      </p>
      <p className="info">
        <strong>Convites:</strong> <br />
        {invite.guests.length} / {invitesConfirmed}
      </p>
      <p className="info">
        <strong>Abriu:</strong>
        <br /> {invite.hasOpened ? 'Sim' : 'Não'}
      </p>
      <p className="info">
        <strong>Presentes dados:</strong> <br />
        {giftsAmount}
      </p>
      <p className="info">
        <strong>Total em presentes:</strong>
        <br /> {giftsTotal}
      </p>
      <div>
        <button
          type="button"
          style={{ background: '#4361ee' }}
          onClick={() => {
            handleEdit(invite)
          }}
        >
          <PencilSimple size={24} />
        </button>
        <button
          type="button"
          onClick={() => {
            handleDelete(invite)
          }}
        >
          <Trash size={24} />
        </button>
      </div>
    </Container>
  )
}
