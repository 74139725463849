import { type IPurchase } from '../../@Types/IPurchase'

export const paymentMethodName = (purchase: IPurchase | undefined) => {
  if (!purchase?.paymentData?.paymentMethodId) return ''

  if (purchase.paymentData.paymentMethodId === 'credit_card') {
    return 'Cartão de crédito'
  }
  if (purchase.paymentData.paymentMethodId === 'debit_card') {
    return 'Cartão de débito'
  }
  if (purchase.paymentData.paymentMethodId === 'pix') {
    return 'Pix'
  }
  if (purchase.paymentData.paymentMethodId === 'bolbradesco') {
    return 'Boleto Bradesco'
  }
  return ''
}
