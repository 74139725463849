import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  background-color: #f5f5f5;
  display: flex;
  padding: 16px;
  border-radius: 16px;

  @media (max-width: 920px) {
    flex-direction: column-reverse;
    padding: 24px;
  }

  h3 {
    margin: 0 0 16px;
    font-size: 24px;
    text-align: left;
  }
`

export const Gifts = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: calc(150px * 3 + 16px * 2);

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 32px 16px;
  }

  @media (max-width: 920px) {
    max-width: 100%;

    ul {
      justify-content: center;
    }
  }
`

export const Gift = styled.li`
  width: 150px;
  max-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 16px;
  }
  h4 {
    margin: 8px 0;
    text-align: center;
  }
  p {
    margin: 0;
    text-align: center;
  }
`

export const PurchaseInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 920px) {
    margin-bottom: 32px;
  }

  section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }

  span {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-color: currentColor;
    border-radius: 50%;
  }

  a {
    display: inline-block;
    margin-top: 16px;
    text-align: center;
    width: 100%;
    color: var(--red-500);
    text-decoration: underline;
  }
`
