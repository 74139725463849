import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { type IInvite } from '../../@Types/IInvite'
import { type IPaymentMethod } from '../../@Types/IPaymentMethod'
import { type IPurchase } from '../../@Types/IPurchase'
import { Footer } from '../../components/Footer'
import { Menu } from '../../components/Menu'
import { useLoading } from '../../hooks/LoadingProvider'
import { api } from '../../services/api'
import { confirmationDateFormatted } from '../../services/utils/confirmationDateFormatted'
import { Form } from './Form'
import { Purchase } from './Purchase'
import { Container, Content, PurchaseList, PurchasesSection } from './styles'

interface ILoadeds {
  purchases?: boolean
  paymentMethods?: boolean
  invite?: boolean
}

export const Confirmation: React.FC = () => {
  const [loadeds, setLoadeds] = useState<ILoadeds>({})
  const [invite, setInvite] = useState<IInvite>()
  const [purchases, setPurchases] = useState<IPurchase[]>([])
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([])
  const { code } = useParams()
  const navigate = useNavigate()

  const { setLoading } = useLoading()

  useEffect(() => {
    if (code) {
      localStorage.setItem('@biafelipe:code', code)
      api.defaults.headers.code = code
    }
  }, [code])

  useEffect(() => {
    async function getInvite() {
      try {
        setLoadeds((state) => ({ ...state, invite: false }))
        const response = await api.get<IInvite>(`/guests/invites`)
        setInvite(response.data)
        setLoadeds((state) => ({ ...state, invite: true }))
      } catch (error) {
        console.error(error)
        setLoading(false)
        navigate('/404')
      }
    }

    getInvite().catch(console.error)
  }, [navigate, setLoading])

  useEffect(() => {
    async function getPurchases() {
      setLoadeds((state) => ({ ...state, purchases: false }))
      const response = await api.get(`/guests/purchases/payments`)
      setPurchases(response.data)
      setLoadeds((state) => ({ ...state, purchases: true }))
    }

    getPurchases().catch(console.error)
  }, [])

  useEffect(() => {
    async function getPaymentMethods() {
      setLoadeds((state) => ({ ...state, paymentMethods: false }))
      const response = await api.get<IPaymentMethod[]>(
        '/guests/purchases/payment-methods',
      )
      setPaymentMethods(response.data)
      setLoadeds((state) => ({ ...state, paymentMethods: true }))
    }

    getPaymentMethods().catch(console.error)
  }, [])

  useEffect(() => {
    if (loadeds.invite && loadeds.purchases && loadeds.paymentMethods) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [loadeds, setLoading])

  return (
    <Container>
      <Menu />
      <Content>
        {invite && (
          <>
            <h2>Confirme sua presença!</h2>
            <h3>Olá, {invite?.name}!</h3>
            <p>
              Por favor, atualizem seus dados e confirmem suas presenças até{' '}
              {confirmationDateFormatted}.
            </p>
            <Form invite={invite} />
          </>
        )}

        {purchases.length > 0 && (
          <PurchasesSection>
            <h3>Presentes dados!</h3>
            <PurchaseList>
              {purchases.map((purchase) => (
                <Purchase
                  paymentMethods={paymentMethods}
                  key={purchase.id}
                  purchase={purchase}
                />
              ))}
            </PurchaseList>
            <h4>Muito obrigado!</h4>
          </PurchasesSection>
        )}
      </Content>
      <Footer />
    </Container>
  )
}
