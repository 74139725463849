import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
`

export const Content = styled.main`
  padding-top: 124px;
  min-height: 100vh;
  @media (max-width: 930px) {
    width: 90%;
    margin: 0 auto;
  }

  > h2 {
    font-size: 32px;
    color: #333;
    margin-bottom: 16px;
    text-align: center;
  }

  > h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 16px;
    text-align: center;
  }

  > p {
    font-size: 16px;
    color: #333;
    margin-bottom: 16px;
    text-align: center;
  }
`

export const PurchasesSection = styled.section`
  width: 100%;
  max-width: 80vw;
  margin: 0 auto;

  > h3 {
    font-size: 32px;
    color: #333;
    margin-bottom: 16px;
    text-align: center;
  }
  > h4 {
    font-size: 24px;
    color: #333;
    margin-top: 32px;
    margin-bottom: 64px;
    text-align: center;
  }
`

export const PurchaseList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 16px;
`
