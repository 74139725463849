import { weddingDate } from './weddingDate'

const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

export const weddingDateFormatted = () => {
  const day = weddingDate.getDate().toString().padStart(2, '0')
  const month = months[weddingDate.getMonth()]
  const year = weddingDate.getFullYear()

  return `${day} de ${month} de ${year}`
}
