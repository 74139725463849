import styled from 'styled-components'

export const Container = styled.li`
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 100%;
  border-radius: 0.8rem;
  padding: 1.6rem 3.2rem 1.6rem 1.6rem;

  @media (max-width: 1100px) {
    padding: 1.6rem;
    flex-direction: column;
    gap: 1.6rem;
    max-width: 300px;
    margin: 0 auto;
  }

  .image {
    width: 8rem;
    min-width: 8rem;
    height: 8rem;
    border-radius: 0.8rem;
    margin-right: 1.6rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.8rem;
      object-fit: cover;
    }
  }

  p {
    flex: 1;
  }

  button {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 0.8rem;
    border: none;
    background-color: #f2f2f2;
    color: #ff206e;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .quantity {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    margin-right: 1.6rem;

    button {
      opacity: 1;
    }
  }

  input {
    width: 4.8rem;
    height: 4.8rem;
    appearance: textfield;
    -moz-appearance: textfield;
    text-align: center;
    border-radius: 0.8rem;
    border: none;
    background-color: #f2f2f2;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
