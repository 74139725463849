import { useEffect, useState, useMemo } from 'react'

import { weddingDate } from '../../../services/utils/weddingDate'
import { weddingDateFormatted } from '../../../services/utils/weddingDateFormatted'
import { weddingHourFormatted } from '../../../services/utils/weddingHourFormatted'
import { Container, DateContainer } from './styles'
import { TimerContainer } from './styles'

export const DateSection: React.FC = () => {
  const [now, setNow] = useState(new Date())

  const weddingDateF = weddingDateFormatted()
  const weddingHourF = weddingHourFormatted()

  const diff = useMemo(() => weddingDate.getTime() - now.getTime(), [now])

  const days = useMemo(() => {
    if (diff < 0) return '0'

    return Math.floor(diff / (1000 * 60 * 60 * 24)).toString()
  }, [diff])
  const hours = useMemo(() => {
    if (diff < 0) return '0'

    return Math.floor((diff / (1000 * 60 * 60)) % 24)
      .toString()
      .padStart(2, '0')
  }, [diff])
  const minutes = useMemo(() => {
    if (diff < 0) return '00'

    return Math.floor((diff / (1000 * 60)) % 60)
      .toString()
      .padStart(2, '0')
  }, [diff])
  const seconds = useMemo(() => {
    if (diff < 0) return '00'

    return Math.floor((diff / 1000) % 60)
      .toString()
      .padStart(2, '0')
  }, [diff])

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Container id="data">
      <h2>Contagem Regressiva</h2>

      {diff > 0 && (
        <TimerContainer>
          <div>
            <span>{days}</span>
            <p>Dias</p>
          </div>
          <div>
            <span>{hours}</span>
            <p>Horas</p>
          </div>
          <div>
            <span>{minutes}</span>
            <p>Minutos</p>
          </div>
          <div>
            <span>{seconds}</span>
            <p>Segundos</p>
          </div>
        </TimerContainer>
      )}

      {diff <= 0 && (
        <TimerContainer className="done">
          <div>
            <p>Enfim casados!</p>
          </div>
        </TimerContainer>
      )}

      <DateContainer>
        <p>{weddingDateF}</p>
        <p>{weddingHourF}</p>
      </DateContainer>

      <p>Obrigado por fazer parte dessa data tão importante pra gente!</p>
    </Container>
  )
}
