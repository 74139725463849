import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

export interface IFormData {
  name: string
  email?: string | null
  phone?: string | null
  guests?: Array<{
    name?: string | null
    table?: string | null
    confirmed?: boolean | null
  }>
}

export const validation = yupResolver(
  yup.object().shape({
    name: yup.string().required('Nome obrigatório'),
    email: yup.string().email('E-mail inválido').nullable(),
    phone: yup.string().nullable(),
    guests: yup.array(
      yup.object().shape({
        name: yup.string().nullable(),
        table: yup.string().nullable(),
        confirmed: yup.boolean().nullable(),
      }),
    ),
  }),
)
