import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../../components/Button'
import { Input } from '../../../../components/Input'
import { TitleContainer } from '../../../../components/TitleContainer'
import { api } from '../../../../services/api'
import { type IFormData, validation } from './form.validation'
import { Container, Form } from './styles'

export const AddUser: React.FC = () => {
  const navigate = useNavigate()
  const controls = useForm<IFormData>({ resolver: validation })

  const handleBack = () => {
    navigate('/admin/users')
  }

  async function handleSubmit(data: IFormData) {
    await api.post('/users', data)
    navigate('/admin/users')
  }

  return (
    <Container>
      <TitleContainer>
        <h1>Adicionar Usuário</h1>
        <Button type="button" style={{ opacity: 0.5 }} onClick={handleBack}>
          Voltar
        </Button>
      </TitleContainer>

      <FormProvider {...controls}>
        <Form onSubmit={controls.handleSubmit(handleSubmit)} autoComplete="off">
          <Input name="name" label="Nome" type="text" placeholder="Nome" />
          <Input
            name="email"
            label="E-mail"
            type="email"
            placeholder="E-mail"
          />
          <Input
            name="password"
            label="Senha"
            type="password"
            placeholder="Senha"
          />
          <Input
            name="passwordConfirmation"
            label="Confirmação de senha"
            type="password"
            placeholder="Confirmação da senha"
          />
          <Button type="submit">Cadastrar</Button>
        </Form>
      </FormProvider>
    </Container>
  )
}
