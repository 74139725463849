import { useEffect } from 'react'

import { useModal } from '..'

import { Container, ModalBackground, ModalContent } from './styles'

interface IModalsProps {
  modals: JSX.Element[]
}

export const Modals: React.FC<IModalsProps> = ({ modals }) => {
  const { closeModal } = useModal()

  function preventClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation()
  }

  useEffect(() => {
    if (modals.length === 0) {
      window.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          closeModal()
        }
      })
    }
  }, [closeModal, modals])

  return (
    <Container>
      {modals.map((modal, index) => (
        <ModalBackground onClick={closeModal} key={index.toString()}>
          <ModalContent onClick={preventClick}>{modal}</ModalContent>
        </ModalBackground>
      ))}
    </Container>
  )
}
