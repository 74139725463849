export const getPaymentStatus = (
  status?: string,
  statusDetail?: string,
): string => {
  if (!status) return ''
  if (status === 'pending') {
    if (statusDetail === 'pending_contingency') {
      return 'Pendente. O pagamento está sendo processado.'
    } else if (statusDetail === 'pending_review_manual') {
      return 'Pendente. O pagamento está sob revisão para determinar sua aprovação ou rejeição.'
    }
    return 'Pendente.'
  } else if (status === 'approved') {
    return 'Aprovado.'
  } else if (status === 'authorized') {
    return 'Pagamento autorizado.'
  } else if (status === 'in_process') {
    return 'Pagamento em processamento.'
  } else if (status === 'in_mediation') {
    return 'O usuário iniciou uma disputa.'
  } else if (status === 'rejected') {
    if (statusDetail === 'cc_rejected_bad_filled_date') {
      return 'Rejeitado. A data de vencimento é inválida.'
    } else if (statusDetail === 'cc_rejected_bad_filled_other') {
      return 'Rejeitado. Dados inválidos.'
    } else if (statusDetail === 'cc_rejected_bad_filled_security_code') {
      return 'Rejeitado. O código de segurança é inválido.'
    } else if (statusDetail === 'cc_rejected_blacklist') {
      return 'Rejeitado. O pagamento foi rejeitado.'
    } else if (statusDetail === 'cc_rejected_call_for_authorize') {
      return 'Rejeitado. O usuário deve autorizar o pagamento.'
    } else if (statusDetail === 'cc_rejected_card_disabled') {
      return 'Rejeitado. O cartão está desativado.'
    } else if (statusDetail === 'cc_rejected_duplicated_payment') {
      return 'Rejeitado. Pagamento duplicado.'
    } else if (statusDetail === 'cc_rejected_high_risk') {
      return 'Rejeitado. O pagamento foi rejeitado.'
    } else if (statusDetail === 'cc_rejected_insufficient_amount') {
      return 'Pagamento rejeitado.'
    } else if (statusDetail === 'cc_rejected_max_attempts') {
      return 'Rejeitado. O usuário atingiu o limite de tentativas.'
    }
    return 'Pagamento rejeitado.'
  } else if (status === 'cancelled') {
    return 'Pagamento cancelado.'
  } else if (status === 'refunded') {
    return 'Pagamento reembolsado.'
  } else if (status === 'charged_back') {
    return 'Pagamento estornado.'
  }
  return status
}
