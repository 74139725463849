import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

export const Container = styled.section`
  flex: 1;
  height: fit-content;
  margin: 0 0 64px 32px;
  border-radius: 8px;
  max-width: 400px;
  min-width: 400px;

  @media (max-width: 1150px) {
    margin: 0 auto 64px;
    min-width: 400px;
    max-width: 100%;
  }

  @media (max-width: 570px) {
    min-width: 100%;
    width: 100%;
  }
`

export const FloatingContainer = styled.div`
  flex: 1;
  background-color: #fff;
  height: fit-content;
  padding: 32px 24px;
  border-radius: 8px;
  max-width: 400px;
  min-width: 400px;
  position: fixed;
  top: 124px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  &.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 400px;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      gap: 16px;
      text-align: center;
    }
  }

  @media (max-width: 1150px) {
    position: initial;
    margin: 0 auto;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  > div {
    margin-bottom: 16px;
  }

  .images {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    img {
      width: 8rem;
      height: 8rem;
      object-fit: cover;
      border-radius: 8px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

      + img {
        margin-left: -4rem;
      }
    }

    .amount-more {
      background-color: #f5f5f5;
      width: 8rem;
      height: 8rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3.2rem;
      font-weight: 700;
      border-radius: 8px;
      margin-left: -4rem;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    }
  }

  .product-names {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .info {
      display: flex;
      gap: 8px;

      p {
        flex: 1;
      }

      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media (max-width: 570px) {
        gap: 4px;
      }

      @media (max-width: 430px) {
        flex-direction: column;
      }
    }

    @media (max-width: 570px) {
      gap: 16px;
    }
  }

  .status {
    margin-top: 32px;
    text-align: center;

    > p {
      padding-top: 8px;
      margin-bottom: 16px;
    }

    .status-text {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      p {
        padding-top: 8px;
      }
    }

    .status-icon {
      width: 48px;
      height: 48px;
      display: inline-block;
      border-radius: 50%;
      background-color: #9e9e9e;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;

      &.approved {
        background-color: #4caf50;
      }
      &.pending {
        background-color: #ffeb3b;
        animation: ${rotate} 2s infinite linear;
      }
      &.in_process {
        background-color: #ff9800;
        animation: ${rotate} 2s infinite linear;
      }
      &.rejected {
        background-color: #f44336;
      }
      &.cancelled {
        background-color: #9e9e9e;
      }
      &.refunded {
        background-color: #9e9e9e;
      }
      &.charged_back {
        background-color: #9e9e9e;
      }
    }
  }
`
