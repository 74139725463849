import styled from 'styled-components'

export const Container = styled.section`
  background-color: #ffffff;
  padding: 50px 64px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: center;
    font-size: 56px;
    font-weight: 500;
    color: var(--red-500);
    font-family: var(--font-cookie);
  }

  p {
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    font-family: var(--font-cookie);
  }

  .gift-list-button {
    margin-top: 64px;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    background-color: var(--red-500);
    color: var(--white);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 430px) {
    padding: 50px 24px 100px;
  }
`

export const GiftList = styled.ul`
  display: flex;
  gap: 24px;
  row-gap: 64px;
  margin-top: 64px;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  max-width: 1000px;

  @media (max-width: 430px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
`
