import styled from 'styled-components'

export const Container = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  cursor: pointer;
  position: relative;
  width: fit-content;
  margin: 0 auto 16px;

  span {
    margin-bottom: 8px;
    font-size: 16px;
    color: #333;
  }

  .preview {
    width: 150px;
    height: 150px;
    border-radius: 0.8rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
  }
`
