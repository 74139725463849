import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { initMercadoPago, Payment } from '@mercadopago/sdk-react'
import { type IPaymentFormData } from '@mercadopago/sdk-react/bricks/payment/type'

import { type IGiftInCart } from '../../../@Types/IGiftInCart'
import { Loader } from '../../../components/Loader'
import { api } from '../../../services/api'
import { Container } from './styles'

interface IPurchasePaymentProps {
  setStep: React.Dispatch<React.SetStateAction<number>>
  setPurchaseId: React.Dispatch<React.SetStateAction<string>>
  products: IGiftInCart[]
  total: number
}

const publicKey = process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY ?? ''
export const PurchasePayment: React.FC<IPurchasePaymentProps> = ({
  setPurchaseId,
  setStep,
  products,
  total,
}) => {
  initMercadoPago(publicKey, { locale: 'pt-BR' })
  const navigate = useNavigate()
  const [preferenceId, setPreferenceId] = useState<string | undefined>()
  const [loading, setLoading] = useState(true)

  const handleSubmit = async (param: IPaymentFormData) => {
    const data = {
      payer: param.formData.payer,
      cardToken: param.formData.token,
      installments: param.formData.installments,
      issuerId: param.formData.issuer_id,
      paymentMethodId: param.formData.payment_method_id,
      products: products.map((item) => ({
        id: item.gift.id,
        quantity: item.quantity,
      })),
    }

    const response = await api.post('/guests/purchases/payments', data)

    localStorage.removeItem('@biafelipe:purchase')
    setPurchaseId(response.data.id)
    setStep(2)
    navigate(`/comprar-presentes/${response.data.id}`)
  }

  useEffect(() => {
    async function createPreference() {
      setLoading(true)
      const currentPreferenceId = localStorage.getItem(
        '@biafelipe:preferenceId',
      )
      if (currentPreferenceId) {
        setPreferenceId(currentPreferenceId)
        setLoading(false)
        return
      }
      const response = await api.post('/guests/purchases/preferences', {
        products: products.map((item) => ({
          id: item.gift.id,
          quantity: item.quantity,
        })),
      })

      setPreferenceId(response.data.preferenceId)
      localStorage.setItem(
        '@biafelipe:preferenceId',
        response.data.preferenceId,
      )
      setLoading(false)
    }

    createPreference().catch(console.error)
  }, [products])

  return (
    <Container>
      {!loading && (
        <Payment
          onSubmit={handleSubmit}
          initialization={{
            amount: total,
            preferenceId,
          }}
          customization={{
            paymentMethods: {
              ticket: ['bolbradesco'],
              bankTransfer: 'all',
              creditCard: 'all',
              debitCard: 'all',
              maxInstallments: 12,
              minInstallments: 1,
              mercadoPago: preferenceId ? 'all' : undefined,
            },
          }}
        />
      )}
      {loading && (
        <div className="loading">
          <p>Carregando...</p>
          <Loader color="#000" />
        </div>
      )}
    </Container>
  )
}
