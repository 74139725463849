import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../components/Button'
import { Input } from '../../../components/Input'
import { useAuth } from '../../../hooks/AuthProvider'
import { Box, Container } from './styles'

interface IFormData {
  email: string
  password: string
}

export const Login: React.FC = () => {
  const navigate = useNavigate()
  const controls = useForm<IFormData>()
  const { doLogin, isLogged } = useAuth()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (data: IFormData): Promise<void> => {
    setLoading(true)
    try {
      await doLogin(data)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (isLogged) {
      navigate('/admin')
    }
  }, [isLogged, navigate])

  if (isLogged) return <div>Redirecting...</div>

  return (
    <FormProvider {...controls}>
      <Container onSubmit={controls.handleSubmit(handleSubmit)}>
        <Box>
          <h1>Login</h1>
          <p>Apenas para administradores</p>
          <Input label="E-mail" name="email" isWhiteBackground />
          <Input
            label="Senha"
            name="password"
            type="password"
            isWhiteBackground
          />
          <Button type="submit" loading={loading}>
            Entrar
          </Button>
        </Box>
      </Container>
    </FormProvider>
  )
}
