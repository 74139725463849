import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

export interface IFormData {
  code: string
}

export const validation = yupResolver(
  yup.object().shape({
    code: yup.string().required('O código é obrigatório.'),
  }),
)
