import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 64px;
  min-height: 400px;

  > div:not(.loading) {
    width: 100%;
  }

  div.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 1150px) {
    margin-bottom: 0;
  }

  @media (max-width: 400px) {
    padding: 0;
  }
`
