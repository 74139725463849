import { createContext, useContext, useEffect, useState } from 'react'

import { type IGiftInCart } from '../@Types/IGiftInCart'

interface ICartContextData {
  cart: IGiftInCart[]
  total: number
  addProduct: (productId: IGiftInCart) => void
  removeProduct: (productId: string) => void
  changeProductQuantity: (productId: string, quantity: number) => void
  clearCart: () => void
}

export const CartContext = createContext<ICartContextData>(
  {} as unknown as ICartContextData,
)

interface ICardProviderProps {
  children: React.ReactNode
}

function getCart() {
  const cart = localStorage.getItem('@biafelipe:cart')

  if (cart) {
    return JSON.parse(cart)
  }

  return []
}

export const CartProvider: React.FC<ICardProviderProps> = ({ children }) => {
  const [cart, setCart] = useState<IGiftInCart[]>(getCart)
  const [total, setTotal] = useState(0)

  const addProduct = (newProduct: IGiftInCart) => {
    const productInCart = cart.find(
      (productInCart) => productInCart.gift.id === newProduct.gift.id,
    )

    if (productInCart) {
      setCart(
        cart.map((product) =>
          product.gift.id === newProduct.gift.id
            ? { ...product, quantity: product.quantity + 1 }
            : product,
        ),
      )
    } else {
      setCart([...cart, { ...newProduct, quantity: newProduct.quantity || 1 }])
    }
  }

  const removeProduct = (productId: string) => {
    setCart(cart.filter((product) => product.gift.id !== productId))
  }

  const clearCart = () => {
    setCart([])
  }

  const changeProductQuantity = (productId: string, quantity: number) => {
    setCart(
      cart.map((product) =>
        product.gift.id === productId ? { ...product, quantity } : product,
      ),
    )
  }

  useEffect(() => {
    const currentStorageCart = localStorage.getItem('@biafelipe:cart')
    if (currentStorageCart !== JSON.stringify(cart)) {
      localStorage.removeItem('@biafelipe:preferenceId')
    }

    localStorage.setItem('@biafelipe:cart', JSON.stringify(cart))
    setTotal(
      cart.reduce((acc, item) => acc + item.gift.price * item.quantity, 0),
    )
  }, [cart])

  return (
    <CartContext.Provider
      value={{
        cart,
        total,
        addProduct,
        removeProduct,
        clearCart,
        changeProductQuantity,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export const useCart = () => {
  const context = useContext(CartContext)

  if (!context) {
    throw new Error('useCart must be used within an CartProvider')
  }

  return context
}
