import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem;
  max-width: 70vh;
  margin: 0 auto;

  h1 {
    color: var(--black);
    font-size: 2.4rem;
  }
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 3.2rem;
`
