import { confirmationDate } from './confirmationDate'

export const confirmationDateFormatted = new Intl.DateTimeFormat('pt-BR', {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  timeZone: 'America/Sao_Paulo',
}).format(confirmationDate)
