import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  max-width: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  h4 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  span {
    margin-top: auto;
    font-size: 14px;
    display: block;
    margin-bottom: 8px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--red-500);
    color: var(--white);
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
    width: 100%;
    text-align: center;

    &.secondary {
      background-color: var(--white);
      color: var(--red-500);
      border: 2px solid var(--red-500);
      margin-top: 8px;
    }
  }

  img {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
  }

  .gifted {
    display: flex;
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    color: var(--white);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 8px;
    border-radius: 8px;
  }

  &.gifted-all {
    pointer-events: none;

    & * {
      opacity: 0.5;
    }
    .gifted-all {
      opacity: 1;
      color: var(--red-500);
      font-weight: 900;
      font-size: 32px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
`
