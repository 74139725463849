import { type IGift } from '../../../@Types/IGift'
import { Container } from './styles'

interface IAddCartModalProps {
  gift: IGift
  onClose: () => void
  onNavigateToCart: () => void
}

export const AddCartModal: React.FC<IAddCartModalProps> = ({
  gift,
  onClose,
  onNavigateToCart,
}) => {
  return (
    <Container>
      <h2>
        O presente <span>{gift.name}</span> foi adicionado ao carrinho!
      </h2>

      <div className="buttons">
        <button type="button" onClick={onClose}>
          Continuar presenteado
        </button>
        <button type="button" onClick={onNavigateToCart}>
          Ir para o carrinho
        </button>
      </div>
    </Container>
  )
}
