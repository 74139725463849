import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

export interface IFormData {
  name: string
  email: string
  password: string
  passwordConfirmation: string
}

export const validation = yupResolver(
  yup.object().shape({
    name: yup.string().required('Nome obrigatório'),
    email: yup.string().required('E-mail obrigatório').email('E-mail inválido'),
    password: yup
      .string()
      .required('Senha obrigatória')
      .min(6, 'No mínimo 6 caracteres'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password')], 'As senhas devem ser iguais')
      .required('Confirmação de senha obrigatória'),
  }),
)
