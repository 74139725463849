import styled from 'styled-components'

export const Container = styled.section`
  height: 50vh;
  padding: 50px 64px 100px;

  h2 {
    font-size: 56px;
    font-weight: 500;
    color: var(--red-500);
    font-family: var(--font-cookie);
    text-align: center;
  }

  @media (max-width: 500px) {
    height: fit-content;
  }

  @media (max-width: 430px) {
    padding: 50px 24px 100px;
  }
`

export const Content = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 32px;
  color: black;

  p {
    font-size: 32px;
    font-weight: 400;
    font-family: var(--font-cookie);
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }

  .error {
    color: var(--red-500);
    font-size: 16px;
    text-align: center;
    font-family: var(--font-inter);
  }

  span {
    display: block;
    font-size: 14px;
    text-align: center;
    padding: 16px 0;
    width: 90%;
    opacity: 0.8;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 80%;

    input {
      width: 100%;
      padding: 16px;
      border: none;
      border-radius: 8px;
    }

    button {
      width: 100%;
      padding: 16px;
      border: none;
      border-radius: 8px;
      background-color: var(--red-500);
      color: var(--white);
    }

    @media (max-width: 500px) {
      width: 100%;
    }
  }
`
