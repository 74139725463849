import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { AdminHome } from './pages/admin/AdminHome'
import { AddGift as AdminAddGift } from './pages/admin/gifts/AddGift'
import { EditGift as AdminEditGift } from './pages/admin/gifts/EditGift'
import { Gifts as AdminGifts } from './pages/admin/gifts/Gifts'
import { AddInvite as AdminAddInvite } from './pages/admin/invites/AddInvite'
import { EditInvite as AdminEditInvite } from './pages/admin/invites/EditInvite'
import { Invites as AdminInvites } from './pages/admin/invites/Invites'
import { Login } from './pages/admin/Login'
import { AddUser as AdminAddUser } from './pages/admin/users/AddUser'
import { EditUser as AdminEditUser } from './pages/admin/users/EditUser'
import { Users as AdminUsers } from './pages/admin/users/Users'
import { Cart } from './pages/Cart'
import { Confirmation } from './pages/Confirmation'
import { Gifts } from './pages/Gifts'
import { Home } from './pages/Home'
import { Purchase } from './pages/Purchase'
import { AdminLayout } from './styles/layouts/AdminLayout'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/confirmacao/:code',
    element: <Confirmation />,
  },
  {
    path: '/lista-de-presentes',
    element: <Gifts />,
  },
  {
    path: '/carrinho',
    element: <Cart />,
  },
  {
    path: '/comprar-presentes',
    element: <Purchase />,
  },
  {
    path: '/comprar-presentes/:purchaseId',
    element: <Purchase />,
  },
  {
    path: '/admin/login',
    element: <Login />,
  },
  {
    path: '/admin',
    element: (
      <AdminLayout>
        <AdminHome />
      </AdminLayout>
    ),
  },
  {
    path: '/admin/users',
    element: (
      <AdminLayout>
        <AdminUsers />
      </AdminLayout>
    ),
  },
  {
    path: '/admin/users/add',
    element: (
      <AdminLayout>
        <AdminAddUser />
      </AdminLayout>
    ),
  },
  {
    path: '/admin/users/edit/:id',
    element: (
      <AdminLayout>
        <AdminEditUser />
      </AdminLayout>
    ),
  },
  {
    path: '/admin/invites',
    element: (
      <AdminLayout>
        <AdminInvites />
      </AdminLayout>
    ),
  },
  {
    path: '/admin/invites/add',
    element: (
      <AdminLayout>
        <AdminAddInvite />
      </AdminLayout>
    ),
  },
  {
    path: '/admin/invites/edit/:id',
    element: (
      <AdminLayout>
        <AdminEditInvite />
      </AdminLayout>
    ),
  },
  {
    path: '/admin/gifts',
    element: (
      <AdminLayout>
        <AdminGifts />
      </AdminLayout>
    ),
  },
  {
    path: '/admin/gifts/add',
    element: (
      <AdminLayout>
        <AdminAddGift />
      </AdminLayout>
    ),
  },
  {
    path: '/admin/gifts/edit/:id',
    element: (
      <AdminLayout>
        <AdminEditGift />
      </AdminLayout>
    ),
  },
  {
    path: '/404',
    element: (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h1>404</h1>
        <p>Page not found</p>
      </div>
    ),
  },
  {
    path: '/:code',
    element: <Confirmation />,
  },
])

export const Router: React.FC = () => {
  return <RouterProvider router={router} />
}
