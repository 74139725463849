import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem;
  max-width: 70vh;
  margin: 0 auto;

  h1,
  h2 {
    color: var(--black);
    font-size: 2.4rem;
  }
`

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 3.2rem;
`

export const ButtonAddGuest = styled.button`
  height: 7.2rem;
  border: 4px dashed var(--red-500);
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  opacity: 0.5;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }
`

export const Row = styled.div`
  display: flex;
  gap: 1.6rem;
  position: relative;

  .close {
    position: absolute;
    bottom: 24px;
    left: -48px;
    cursor: pointer;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--red-500);
    background: none;
    border: none;
  }
`
