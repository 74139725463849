import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { type IUser } from '../../../../@Types/IUser'
import { Button } from '../../../../components/Button'
import { ModalYesNo } from '../../../../components/ModalYesNo'
import { TitleContainer } from '../../../../components/TitleContainer'
import { useLoading } from '../../../../hooks/LoadingProvider'
import { useModal } from '../../../../hooks/ModalProvider'
import { api } from '../../../../services/api'
import { Container, UserList } from './styles'
import { UserRow } from './UserRow'

export const Users: React.FC = () => {
  const navigate = useNavigate()
  const [users, setUsers] = useState<IUser[]>([])
  const { openModal, closeModal } = useModal()
  const { loading, setLoading } = useLoading()

  const loadUsers = useCallback(async () => {
    setLoading(true)
    const response = await api.get('/users')
    setUsers(response.data)
    setLoading(false)
  }, [setLoading])

  async function deleteUser(user: IUser) {
    setLoading(true)
    await api.delete(`/users/${user.id}`)
    loadUsers().catch(console.error)
    closeModal()
  }

  function handleDelete(user: IUser) {
    openModal(
      <ModalYesNo
        title="Deletar Usuário"
        message={`Tem certeza que quer deletar o usuário ${user.name}?`}
        onClose={closeModal}
        onNo={closeModal}
        onYes={() => {
          deleteUser(user).catch(console.error)
        }}
      />,
    )
  }

  function handleEdit(user: IUser) {
    navigate(`/admin/users/edit/${user.id}`)
  }

  function handleAddUser() {
    navigate('/admin/users/add')
  }

  useEffect(() => {
    loadUsers().catch(console.error)
  }, [loadUsers])

  return (
    <Container>
      <TitleContainer>
        <h1>Usuários</h1>
        <Button type="button" onClick={handleAddUser}>
          Adicionar
        </Button>
      </TitleContainer>

      <UserList>
        {users.map((user) => (
          <UserRow
            key={user.id}
            user={user}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        ))}
        {users.length === 0 && !loading && (
          <li className="empty">Nenhum usuário cadastrado</li>
        )}
      </UserList>
    </Container>
  )
}
